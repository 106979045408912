<script lang="ts">
import { Vue, Component } from 'vue-facing-decorator';
import NodeRedEditor from '@/components/NodeRedEditor.vue';
import { noderedInstances } from '@/assets/nodered.instances';
import { copyToClipboard } from '@/classes/utils';

@Component({
  components: { NodeRedEditor }})
export default class NodeRedManagement extends Vue {
  private copyToClipboard: (text: string) => boolean = copyToClipboard;
  private loading = false;
  private nodeRedInstances = noderedInstances;
  private headers = [
    { title: 'Name', value: 'name', width: '30%' },
    { title: 'Domain name', value: 'domainName', width: '50%' },
    { title: '', value: 'actions', sortable: false, width: '20%' },
  ];

  private getNodeRedDashboardUrl(domainName: string) {
    return `https://${domainName}/dashboard`;
  }
}
</script>

<template>
  <div class="fill-height embed-container overflow-x-hidden" v-if="!$route.params.id">
    <div class="mt-10">
      <v-row class="pt-2 mx-4 pb-2" justify="space-between" align="center" dense>
        <v-col cols="12" md="6">
          <v-row align="center" dense>
            <v-col cols="12">
              <h2>Node-RED Management</h2>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        :loading="loading"
        color="primary"
        :headers="headers"
        :items="nodeRedInstances"
        items-per-page="25"
        return-object
        height="calc(100vh - 145px)"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn class="ma-1" width="80" size="small" variant="tonal" color="primary" :to="`/admin/nodered/${item.id}`">Open</v-btn>
<!--          <v-btn class="ma-1" width="100" size="small" variant="tonal" color="primary" @click="copyToClipboard(getNodeRedDashboardUrl(item.domainName))">Copy URL</v-btn>-->
        </template>
      </v-data-table>
    </div>
  </div>
  <router-view v-else></router-view>
</template>

<style scoped>

</style>
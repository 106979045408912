import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fill-height embed-container overflow-x-hidden" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("iframe", {
      ref: "frame",
      class: "embed-container",
      width: "100%",
      height: "100%",
      style: {"border":"none"},
      src: _ctx.nodeRedDashboardUrl
    }, null, 8, _hoisted_2), [
      [_vShow, !_ctx.loading]
    ])
  ]))
}
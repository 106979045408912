import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mr-3 font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createBlock(_Transition, { name: "toast-show" }, {
    default: _withCtx(() => [
      (_ctx.mutableShowToastBoolean)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_v_alert, {
              border: "start",
              color: _ctx.type,
              closable: true,
              density: "compact",
              "onClick:close": _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateShowToast(false)))
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_v_icon, {
                  icon: _ctx.icon,
                  color: "white",
                  class: "mt-1"
                }, null, 8, ["icon"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_row, {
                  "no-gutters": "",
                  class: "fill-height",
                  align: "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "auto"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.heading), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "auto"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.body), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["color"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
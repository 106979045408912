import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-677e9192"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "nav"
}
const _hoisted_2 = {
  class: "snackbar",
  style: {"z-index":"9999"}
}
const _hoisted_3 = {
  class: "toast",
  style: {"z-index":"9999"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_Snackbar = _resolveComponent("Snackbar")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createElementBlock("div", {
    class: "fill-height",
    key: _ctx.$vuetify.theme.themes.currentTheme
  }, [
    (_ctx.$route.matched.some((r) => r.path !== '404'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_v_navigation_drawer, {
            color: "tertiary",
            class: "elevation-0",
            modelValue: _ctx.drawer,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.drawer) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { class: "flex-column justify-space-between fill-height" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        flat: "",
                        color: "transparent"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.mdAndUp)
                            ? (_openBlock(), _createBlock(_component_v_card_title, {
                                key: 0,
                                class: "d-flex justify-center"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_img, {
                                    src: _ctx.landscapeLogoSrc,
                                    cover: "",
                                    "max-width": "200",
                                    "max-height": "100",
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (!!_ctx.tenantStore.tenantId ? _ctx.$router.push({name: 'applications'}) : _ctx.$router.push({name: 'tenants'})))
                                  }, null, 8, ["src"])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_v_list, {
                            disabled: !_ctx.tenantStore.tenantId,
                            nav: "",
                            "base-color": "white",
                            color: "primary",
                            class: _normalizeClass(["custom-list", {'mt-4': !!_ctx.smAndDown}])
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item, {
                                link: "",
                                to: "/",
                                disabled: !_ctx.tenantStore.tenantId
                              }, {
                                prepend: _withCtx(() => [
                                  _createVNode(_component_v_icon, {
                                    color: "primary",
                                    end: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("mdi-view-dashboard")
                                    ]),
                                    _: 1
                                  })
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item_title, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode("Applications")
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["disabled"]),
                              (_ctx.userStore.hasPermission('user:read'))
                                ? (_openBlock(), _createBlock(_component_v_list_item, {
                                    key: 0,
                                    exact: "",
                                    link: "",
                                    to: "/users",
                                    disabled: !_ctx.tenantStore.tenantId
                                  }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, {
                                        color: "primary",
                                        end: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-account-group")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("User management")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["disabled", "class"]),
                          (_ctx.userStore.hasPermission('administration:manage'))
                            ? (_openBlock(), _createBlock(_component_v_divider, {
                                key: 1,
                                class: "mx-4"
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.userStore.hasPermission('administration:manage'))
                            ? (_openBlock(), _createBlock(_component_v_list, {
                                key: 2,
                                density: "compact",
                                nav: "",
                                "base-color": "white",
                                color: "primary",
                                class: "custom-list",
                                modelValue: _ctx.activeSubMenu,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeSubMenu) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item, {
                                    onClick: _ctx.clearTenantId,
                                    disabled: !_ctx.tenantStore.tenantId,
                                    ripple: false
                                  }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, {
                                        color: "primary",
                                        end: "",
                                        class: _normalizeClass({'darken-menu': !_ctx.tenantStore.tenantId})
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-arrow-left")
                                        ]),
                                        _: 1
                                      }, 8, ["class"])
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, {
                                        class: _normalizeClass({'darken-menu': !_ctx.tenantStore.tenantId})
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Exit tenant")
                                        ]),
                                        _: 1
                                      }, 8, ["class"])
                                    ]),
                                    _: 1
                                  }, 8, ["onClick", "disabled"]),
                                  _createVNode(_component_v_list_item, {
                                    exact: "",
                                    link: "",
                                    to: "/admin/tenants"
                                  }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, {
                                        color: "primary",
                                        end: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-store")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Tenant accounts")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_list_item, {
                                    exact: "",
                                    link: "",
                                    to: "/admin/superusers"
                                  }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, {
                                        color: "primary",
                                        end: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-account-supervisor")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Super user management")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_list_item, {
                                    exact: "",
                                    link: "",
                                    to: "/admin/applications"
                                  }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, {
                                        color: "primary",
                                        end: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-application-cog")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Application management")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_list_item, {
                                    link: "",
                                    to: "/admin/nodered"
                                  }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, {
                                        color: "primary",
                                        end: ""
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-vector-line")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Node-RED management")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["modelValue"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_card, {
                        flat: "",
                        color: "transparent"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, {
                            nav: "",
                            "base-color": "primary",
                            density: "compact",
                            class: "custom-list",
                            modelValue: _ctx.activeBottomMenu,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.activeBottomMenu) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item, { color: "primary" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item, {
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.userStore.userLogout())),
                                    ripple: false
                                  }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, { end: "" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-logout-variant")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Log out")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_list_item, { to: {name: 'knowledgebase'} }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, { end: "" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-help-circle")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Help")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_v_list_item, {
                                    ripple: false,
                                    to: {name: 'privacy'}
                                  }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, { end: "" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode("mdi-shield-lock")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode("Privacy Policy")
                                        ]),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  false
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "auto",
                        class: "pa-0 d-flex justify-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_switch, {
                            class: "mt-0",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.setDebugMode({mode: 'new_hierarchy', value: $event})))
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          (_ctx.mdAndDown)
            ? (_openBlock(), _createBlock(_component_v_app_bar, {
                key: 0,
                height: "64",
                flat: "",
                density: "compact"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, {
                    justify: "space-between",
                    align: "center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "auto",
                        class: "mx-2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, {
                            color: "primary",
                            onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.drawer = !_ctx.drawer), ["stop"])),
                            size: _ctx.smAndDown ? 32 : 52
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-menu")
                            ]),
                            _: 1
                          }, 8, ["size"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_col, { class: "d-flex align-center justify-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_router_link, { to: "/" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_img, {
                                src: _ctx.landscapeLogoSrc,
                                cover: "",
                                "max-width": "180",
                                height: "64"
                              }, null, 8, ["src"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_ctx.smAndUp)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 0,
                            cols: "auto"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_sheet, {
                                width: _ctx.smAndDown ? 32 : 52,
                                height: "52",
                                color: "transparent"
                              }, null, 8, ["width"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_main, { class: "fill-height" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationStore.snackbarMessages, (snackbar, key) => {
            return (_openBlock(), _createBlock(_component_Snackbar, {
              class: "my-1 mx-1",
              key: key,
              ref_for: true,
              ref: key,
              body: snackbar.message,
              open: !!_ctx.notificationStore.snackbarMessages,
              icon: snackbar.icon,
              onUpdateShowSnackbar: ($event: any) => (_ctx.checkRemoveSnackbar($event, key))
            }, null, 8, ["body", "open", "icon", "onUpdateShowSnackbar"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationStore.toastMessages, (message, key) => {
            return (_openBlock(), _createBlock(_component_Toast, {
              class: "my-1 mx-1",
              key: key,
              ref_for: true,
              ref: key,
              type: "error",
              heading: "An error occurred:",
              body: message,
              onUpdateShowToast: ($event: any) => (_ctx.checkRemoveToast($event, key))
            }, null, 8, ["body", "onUpdateShowToast"]))
          }), 128))
        ]),
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ]))
}
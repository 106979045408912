import Axios from 'axios';
import { appAxios } from '../app.axios';
import TenantService from './tenant.service';

export class ApplicationService {
  public async getApplication(applicationId: string) {
    const url = `/applications/${applicationId}`;
    return (await appAxios.get(url)).data;
  }
  public async getApplicationsForTenant(tenantId: number) {
    const url = `/tenants/${tenantId}/applications`;
    return (await appAxios.get(url)).data;
  }
  public async getAllApplications() {
    const url = `/applications`;
    return (await appAxios.get(url)).data;
  }

  public async registerApplication(app: { name: string; type: string; description?: string; details?: any; }) {
    const url = `/applications`;
    return (await appAxios.post(url, app)).data;
  }
  public async removeApplication(applicationId: number) {
    const url = `/applications/${applicationId}`;
    return (await appAxios.delete(url)).data;
  }
  public async updateApplication(app: { id?: number; name?: string; description?: string; details?: any }) {
    const application = {
      name: app.name,
      description: app.description,
      details: app.details
    }
    const url = `/applications/${app.id}`;
    return (await appAxios.put(url, application)).data;
  }

  public async updateHybridApplicationUserAccess(tenantId: number, users: any ) {
    const url = `/tenants/${tenantId}/hybrid`;
    return (await appAxios.put(url, users)).data;
  }

  public async makeExternalApplicationAPICall(tenantId: number, applicationId: number, url: string) {
    const externalAppAxios = Axios.create();

    const tokenResult = await TenantService.getTokenUrlAndToken(tenantId, applicationId);

    const externalAppToken = (await externalAppAxios.post(tokenResult.url, {token: tokenResult.token, tenant_id: tenantId})).data;

    externalAppAxios.defaults.headers.common.Authorization = `Bearer ${externalAppToken}`;

    url = url.replace(/{TenantId}/g, tenantId.toString());

    return (await externalAppAxios.get(url)).data

  }


}

export default new ApplicationService();

<script lang="ts">
import {Vue, Component, Prop, Watch} from 'vue-facing-decorator';
import {
  ChangeEvent,
  ChangeEventLevel,
  createEmbeddingContext, EmbeddingEvent,
  EmbeddingEvents,
  ExperienceFrameMetadata,
  IEmbeddingContext
} from 'amazon-quicksight-embedding-sdk';

@Component({})
export default class QuicksightDashboard extends Vue {
  @Prop({ default: '' }) private url!: string;
  private embeddingContext: IEmbeddingContext | null = null;

  @Watch('url', {immediate: true, deep: true})
  private onUrlPopulate() {
    if (this.url.length) {
      this.createContext();
    }
  }

  private async createContext() {
    this.embeddingContext = await createEmbeddingContext({
      onChange: (changeEvent, metadata) => {
        console.log('Context received a change', changeEvent, metadata);
      },
    });

    const frameOptions = {
      url: this.url,
      container: '#quicksight-container',
      withIframePlaceholder: true,
      onChange: (changeEvent: any) => {
        if (changeEvent.eventLevel === 'ERROR') {
          console.log(`Do something when embedding experience failed with "${changeEvent.eventName}"`);
          return;
        }
        switch (changeEvent.eventName) {
          case 'FRAME_MOUNTED': {
            console.log("Do something when the experience frame is mounted.");
            break;
          }
          case 'FRAME_LOADED': {
            console.log("Do something when the experience frame is loaded.");
            break;
          }
          case 'FRAME_REMOVED': {
            console.log("Do something when the experience frame is removed.");
            break;
          }
        }
      },
    };
    const contentOptions = {
      onMessage: async (messageEvent: EmbeddingEvents, metadata?: ExperienceFrameMetadata) => {
        switch (messageEvent.eventName) {
          case 'CONTENT_LOADED': {
            console.log("Do something when the embedded experience is fully loaded.");
            break;
          }
          case 'ERROR_OCCURRED': {
            console.log("Do something when the embedded experience fails loading.");
            break;
          }
        }
      }
    };

    const embeddedDashboardExperience = await this.embeddingContext.embedDashboard(frameOptions, contentOptions);

  }

}
</script>

<template>
  <div class="fill-height">
    <div id="quicksight-container" class="embed-container fill-height"></div>
  </div>
</template>

<style>
</style>
<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { useNotificationStore } from '@/stores/notificationStore';

@Component({})
export default class ExternalApp extends Vue {
  @Prop({ default: '' }) private url!: string;
  private loading = true;
  private notificationStore = useNotificationStore();

  private mounted() {
    if (this.$refs?.frame) {
      (this.$refs.frame as HTMLElement).addEventListener('load', () => {
        this.loading = false;
      })
    }
  }

  private get appUrl(): string | undefined {
    return this.url;
  }

}
</script>

<template>
  <div class="fill-height embed-container overflow-x-hidden">
    <iframe v-show="!loading" ref="frame" class="embed-container" width="100%" height="100%" style="border: none" :src="appUrl" allowUserMedia :allow="`camera`"></iframe>
  </div>
</template>

<style scoped>

</style>

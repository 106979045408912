<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { noderedInstances } from '@/assets/nodered.instances';

@Component({
  components: { }
})
export default class NodeRedEditor extends Vue {
  private activeApp = 0;
  private loading = false;
  private noderedInstances = noderedInstances;

  private get domainName() {
    const domainId = this.$route.params.id;
    return this.noderedInstances[Number(domainId) - 1]?.domainName;
  }

  private get dashboardLoginPath() {
    return (process.env.VUE_APP_NR_LOGIN_PATH || '').trim().replace(/^\/|\/$/g, '');
  }

  private get nodeRedEditorUrl() {
    return `https://${this.domainName}/auth/strategy/?token=${localStorage.getItem('token')}`;
  }

  private get nodeRedDashboardUrl() {
    return `https://${this.domainName}/${this.dashboardLoginPath}?token=${encodeURIComponent(localStorage.getItem('token') || '')}`;
  }

}
</script>

<template>
  <div class="fill-height embed-container overflow-x-hidden">
    <v-app-bar height="64" flat>
      <v-tabs bg-color="background" color="primary" v-model="activeApp" class="menu-tabs" :disabled="loading">
        <v-tab value="editor">Editor</v-tab>
        <v-tab value="dashboard">Dashboard</v-tab>
      </v-tabs>
    </v-app-bar>
    <v-progress-linear v-if="loading" :indeterminate="loading" color="primary" height="4" style="z-index: 99"></v-progress-linear>
    <v-card variant="flat" class="rounded-0 pa-0 fill-height" :class="loading ? 'mt-n1' : ''" style="z-index: 98;">
      <v-window v-model="activeApp" class="fill-height">
        <v-window-item value="editor" class="fill-height">
          <div class="fill-height embed-container overflow-x-hidden">
            <iframe ref="frame" class="embed-container" width="100%" height="100%" style="border: none" :src="nodeRedEditorUrl"></iframe>
          </div>
        </v-window-item>
        <v-window-item value="dashboard" class="fill-height">
          <div class="fill-height embed-container overflow-x-hidden">
            <iframe ref="frame" class="embed-container" width="100%" height="100%" style="border: none" :src="nodeRedDashboardUrl"></iframe>
          </div>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<style>
#red-ui-header {
  margin-top: -64px !important;
}
</style>
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fill-height" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.applicationType)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.applicationType), {
          key: 0,
          url: _ctx.embed.url,
          application: _ctx.application,
          tenantId: _ctx.tenantStore.tenantId
        }, null, 8, ["url", "application", "tenantId"]))
      : _createCommentVNode("", true)
  ]))
}
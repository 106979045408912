import {ThemeDefinition} from "vuetify";

export const currentTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#7f7f7f',
    secondary: '#454545',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
}

export const logo = `https://fakeimg.pl/200x64?text=ThemeLogo+2&font=noto`
export const favicon = 'https://fakeimg.pl/32x32/454545'
export const font = { name: 'Roboto', url: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap'};

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Layout = _resolveComponent("Layout")!
  const _component_LoginFlow = _resolveComponent("LoginFlow")!
  const _component_ResetFlow = _resolveComponent("ResetFlow")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { id: "app" }, {
    default: _withCtx(() => [
      (_ctx.$route.path.startsWith('/') && !_ctx.$route.path.endsWith('login') && !_ctx.$route.path.endsWith('reset'))
        ? (_openBlock(), _createBlock(_component_Layout, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.$route.path.endsWith('login'))
        ? (_openBlock(), _createBlock(_component_LoginFlow, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.$route.path.endsWith('reset'))
        ? (_openBlock(), _createBlock(_component_ResetFlow, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
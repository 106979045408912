import { useNotificationStore } from "@/stores/notificationStore";

export function copyToClipboard(text: string): boolean {
  try {
    const win = (window as any);
    // Check for modern clipboard API
    if (win.clipboardData && win.clipboardData.setData) {
      return win.clipboardData.setData('Text', text) as boolean;  // Security exception may be thrown
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.addEventListener('focusin', (e) => e.stopPropagation()); // Dialog fix
      textarea.textContent = text;
      textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page.
      document.body.appendChild(textarea);
      try {
        textarea.select();
        const notificationStore = useNotificationStore();
        notificationStore.addSnackbar({ icon: 'mdi-clipboard-outline', message: 'Successfully copied to clipboard' });
        return document.execCommand('copy');  // Security exception may be thrown.
      } finally {
        document.body.removeChild(textarea);
      }
    }
  } catch (ex) {
    // tslint:disable-next-line
    console.warn('Copy to clipboard failed.', ex);
    return false;
  }
  // tslint:disable-next-line
  console.warn('Copy to clipboard failed as it is unsupported!');
  return false;
}
<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import QuicksightDashboard from "@/components/applications/QuicksightDashboard.vue";
import ApplicationService from "@/services/application.service";
import {Application} from "@/types";
import TenantService from "@/services/tenant.service";
import {useTenantStore} from "@/stores/tenantStore";
import ExternalApp from "@/components/applications/ExternalApp.vue";
import HybridApp from '@/components/applications/HybridApp.vue';
import NodeRedApp from '@/components/applications/NodeRedApp.vue';

@Component({})
export default class EmbeddedApplication extends Vue {
  private application: any = null;
  private embed: { url: string } = { url: '' };
  private tenantStore = useTenantStore();

  private async mounted() {
    if (this.$route.params && this.$route.params.applicationId) {
      if (this.tenantStore.tenantId) {
        const id = Array.isArray(this.$route.params.applicationId) ? this.$route.params.applicationId[0] : this.$route.params.applicationId;
        this.application = await TenantService.getApplication(this.tenantStore.tenantId, Number(id));

        if (this.application.type !== 'nodered') {
          this.embed = await TenantService.getEmbedUrl(this.tenantStore.tenantId, Number(this.$route.params.applicationId));
        }
      }
    }
  }
  private get applicationType() {
    const applicationTypes: Record<string, any> = {
      quicksight: QuicksightDashboard,
      external: ExternalApp,
      nodered: NodeRedApp,
      hybrid: HybridApp,
    }
    // TODO: Better fall through
    if (!this.application || !this.application.type) {
      return QuicksightDashboard;
    }
    return applicationTypes[this.application.type];
  }
}
</script>

<template>
  <div class="fill-height">
    <component :is="applicationType" :url="embed.url" :application="application" v-if="applicationType" :tenantId="tenantStore.tenantId"></component>
  </div>
</template>

<style>
.embed-container {
  line-height: 0;
}
</style>

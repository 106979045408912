import router from "@/router";
import { v4 as uuidv4 } from 'uuid';
import {defineStore} from "pinia";
import { appAxios } from '@/app.axios';
import UserService from "@/services/user.service";
import {useTenantStore} from "@/stores/tenantStore";
import { Application, Role, User } from '@/types';
import ApplicationService from '@/services/application.service';

interface State {
  appLoading?: boolean;
  user?: any;
  appFrameworkToken: string | null;
  preLoginRoute: string | null;
  permissions: string[];
  toastErrors: any;
  debugMode: boolean;
}

export const useUserStore = defineStore( 'userStore', {
  state: (): State => ({
    user: {},
    appFrameworkToken: null,
    preLoginRoute: '',
    permissions: [],
    toastErrors: [],
    debugMode: false,
  }),
  getters: {
    isLoggedIn: state => {
      return !!state.appFrameworkToken;
    },
    hasPermission: (state) => (permission: string) => {
      return state.permissions.includes(permission);
    },
  },
  actions: {
    async goHome() {
      await router.push({ name: 'applications' });
    },
    async userLogout() {
      const tenantStore = useTenantStore();
      await tenantStore.getTenantTheme();
      // Clear token
      this.appFrameworkToken = null;
      localStorage.removeItem('token')

      // Clear user
      this.user = null;

      // Clear tenant ID
      localStorage.removeItem('application_framework_tenant')
      tenantStore.tenantId = null;

      await router.replace({ name: 'login' });
    },
    setToken(token: string) {
      this.appFrameworkToken = token;
      appAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
      setLocalStorage('token', token);
    },
    async userLogin(jwt: string) {
      // Set token
      this.setToken(jwt)

      // Set route
      let routeName = 'applications';
      if (this.preLoginRoute) {
        const route: any = this.preLoginRoute
        if (route.name) {
          routeName = route.name;
        }
        this.preLoginRoute = null;
      }
      await this.refreshUser();

      if (this.user?.roles.map((r: Role) => r.id).includes(3)) { // TODO: Replace this with hasPermission once permissions are fetched from API
        routeName = 'tenants';
      }

      await router.replace({ name: routeName });
    },
    async refreshUser() {
      const user = await UserService.getCurrentUser();

      this.setUser(user);
    },
    setUser(user: User) {
      this.user = user;
      const tenantStore = useTenantStore();
      if (!tenantStore.tenantId) {
        const tenantId = user.tenant_id || null;
        tenantStore.setTenantId(tenantId);
      }

      const userPermissions = ['tenant:read', 'application:read'];
      const managerPermissions = ['application:manage', 'user:read', ...userPermissions];
      const adminPermissions = [...managerPermissions, 'administration:read', 'administration:manage']
      let permissions = userPermissions;

      if (user.roles?.map((r) => r.id).includes(1)) {
        permissions = managerPermissions;
      }
      if (user.roles?.map((r) => r.id).includes(3)) {
        permissions = adminPermissions;
      }

      this.permissions = permissions;

    },
    showToastError(message: string): string {
      const key: string = uuidv4();
      // Set toast errors
      const messages: {[id: string]: string} = {};
      Object.assign(messages, this.toastErrors);
      messages[key] = message;
      this.toastErrors = messages;

      return key;
    },
  }
})

function setLocalStorage(key: string, value: string) {
  if (value === null || value === undefined) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, value);
  }
}

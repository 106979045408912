export const noderedInstances: { id: number; name: string; domainName: string; }[] = [
  { id: 1, name: 'Low Code 1', domainName: `rfworavxax.nodered.iotaccesspoint.net`, },
  { id: 2, name: 'Low Code 2', domainName: 'mpkpivchxb.nodered.iotaccesspoint.net', },
  { id: 3, name: 'Low Code 3', domainName: 'bfdudkbpwu.nodered.iotaccesspoint.net', },
  { id: 4, name: 'Low Code 4', domainName: 'upbuskaquu.nodered.iotaccesspoint.net', },
  { id: 5, name: 'Low Code 5', domainName: 'rtdqcnuczc.nodered.iotaccesspoint.net', },
  { id: 6, name: 'Low Code 6', domainName: 'bhjrpzejic.nodered.iotaccesspoint.net', },
  { id: 7, name: 'Low Code 7', domainName: 'cmsxihkbiv.nodered.iotaccesspoint.net', },
  { id: 8, name: 'Low Code 8', domainName: 'vvkgnubcyb.nodered.iotaccesspoint.net', },
  { id: 9, name: 'Low Code 9', domainName: 'nliksnools.nodered.iotaccesspoint.net', },
  { id: 10, name: 'Low Code 10', domainName: 'ytwksvlwwu.nodered.iotaccesspoint.net', },
]
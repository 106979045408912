import { h } from 'vue';
import {createRouter, createWebHistory, RouteRecordRaw, RouterView} from "vue-router";
import ResetFlow from "@/views/login/ResetFlow.vue";
import { useUserStore } from '@/stores/userStore'
import Applications from "@/views/Applications.vue";
import SuperUserManagement from "@/views/SuperUserManagement.vue";
import Tenants from "@/views/Tenants.vue";
import UserManagement from "@/views/UserManagement.vue";
import {useTenantStore} from "@/stores/tenantStore";
import ApplicationManagement from "@/views/ApplicationsManagement.vue";
import {Role} from "@/types";
import NotFound from "@/views/NotFound.vue";
import EmbeddedApplication from "@/views/EmbeddedApplication.vue";
import ApplicationConfiguration from "@/views/ApplicationConfiguration.vue";
import AppNotFound from '@/views/AppNotFound.vue';
import UserService from '@/services/user.service';
import NodeRedManagement from '@/views/NodeRedManagement.vue';
import NodeRedEditor from '@/components/NodeRedEditor.vue';

export enum RouteScope {
  Public,
  Private,
  Admin
}

const routes: Array<any> = [
  {
    path: '/',
    name: 'index',
    alias: '/applications',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        name: 'applications',
        component: Applications,
        meta: {
          accountIdRequired: true,
          scope: RouteScope.Private,
        }
      },
      {
        path: '/applications/:applicationId',
        name: 'application',
        component: EmbeddedApplication,
        meta: {
          accountIdRequired: true,
          scope: RouteScope.Private,
        }
      },
      {
        path: '/applications/:applicationId/configuration',
        name: 'manage-application-users',
        component: ApplicationConfiguration,
        meta: {
          accountIdRequired: true,
          scope: RouteScope.Private,
        }
      },
    ],
    meta: {
      accountIdRequired: true,
      scope: RouteScope.Private,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: UserManagement,
    meta: {
      accountIdRequired: true,
      scope: RouteScope.Private,
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: 'tenants',
        name: 'tenants',
        component: Tenants
      },
      {
        path: 'superusers',
        name: 'superusers',
        component: SuperUserManagement
      },
      {
        path: 'applications',
        name: 'application-management',
        component: ApplicationManagement
      },
      {
        path: 'nodered',
        name: 'nodered-management',
        component: NodeRedManagement,
        children: [
          {
            path: ':id',
            name: 'nodered-editor',
            component: NodeRedEditor
          },
        ]
      }
    ],
    meta: {
      scope: RouteScope.Admin,
    },
  },
  {
    path: '/login',
    name: 'login',
    components: () => import('@/views/login/LoginFlow.vue'),
    children: [],
    meta: {
      accountIdRequired: false,
      scope: RouteScope.Public,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound,
    meta: {
      scope: RouteScope.Public,
      accountIdRequired: false
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: ResetFlow,
    meta: {
      scope: RouteScope.Public,
    }
  },
  {
    path: '/knowledgebase',
    name: 'knowledgebase',
  },
  {
    path: '/privacy',
    name: 'privacy',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const tenantStore = useTenantStore();
  // Are we looking at a protected route?
  if (!to.matched.some((record) => record.meta.scope === RouteScope.Public)) {
    // Everything behind authentication //
    const localStorageToken = localStorage.getItem('token');
    if (localStorageToken) {
      userStore.setToken(localStorage.getItem('token')!);

      // This works around Vue not waiting for refreshUser dispatch to complete
      const tenantId = localStorage.getItem('application_framework_tenant');
      if (tenantId) {
        tenantStore.setTenantId(Number(tenantId));

        // Get and set theming for tenant
        await tenantStore.getTenantTheme(Number(tenantId));
      }
      await userStore.refreshUser();
    }
    // Always set pre-login route in case of 401
    userStore.preLoginRoute = to.fullPath;

    // Check we actually have an API token otherwise hit the login
    if (!userStore.appFrameworkToken && (!localStorageToken || localStorageToken === 'null')) {
      next({name: 'login'});
      return;
    }

    // If a user only has one application and has access to it, go to it
    if (userStore.user.applications?.length === 1) {
      const userIsTenantUser = userStore.user.roles.find((r: { slug: string }) => r.slug === 'user');
      const hasAppAccess = userStore.user.application_roles.find((ar: any) => ar.application_id === userStore.user.applications?.[0].id);
      
      if (to.matched.some((record) => record.name === 'applications') && userIsTenantUser && hasAppAccess) {
        next({name: 'application', params: { applicationId: userStore.user.applications?.[0].id }})
        return;
      }
    }

    if (to.matched.some((record) => record.meta.scope === RouteScope.Admin)) { // Are we on a admin route, if so do we have permissions, if not goto home
      // TODO: Re-implement when permissions are present
      // if (
      //   !userStore.permissions ||
      //   !userStore.permissions.length ||
      //   !userStore.permissions.some((p: string) => p.includes('administration:'))
      // ) {
      //   next({name: 'applications'});
      //   return;
      // }
      if (!userStore.user.roles.map((r: Role) => r.id).includes(3)) {
        next({name: 'applications'});
        await tenantStore.getTenantTheme();
        return;
      }
    }
    else if (!tenantStore.tenantId) {
      if (
        userStore.permissions &&
        userStore.permissions.length &&
        userStore.permissions.some((p: string) => p.includes('administration:'))
      ) {
        if (to.matched.some((record) => !!record.meta.tenantIdRequired)) {
          next({name: 'tenants'});
          return;
        }
      }
    }


  }
  next();
});

export default router;

import {createVuetify, ThemeDefinition} from "vuetify";
import { useFavicon, useLocalStorage, useStyleTag } from '@vueuse/core';
import {aliases, mdi} from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import 'vuetify/styles';
import axios from "axios";
import {getPublicContentURl} from '../util'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const {currentTheme, logo, favicon, font} = require(`@/themes/default`)


const theme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    'surface-bright': '#FFFFFF',
    'surface-light': '#EEEEEE',
    'surface-variant': '#424242',
    'on-surface-variant': '#EEEEEE',
    primary: '#1867C0',
    'primary-darken-1': '#1F5592',
    secondary: '#48A9A6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
  variables: {
    'border-color': '#000000',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.60,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#000000',
  }
}
export const vuetify = {
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'currentTheme',
    themes: {
      currentTheme: {
        dark: theme.dark,
        colors: theme.colors
      }
    }
  },
  components,
  directives,
};


export async function loadTheme() {
  const defaultTheme = (await axios.get(getPublicContentURl('themes/default.json'))).data;
  const defaultFavicon = getPublicContentURl('themes/favicon.ico');
  const icon = useFavicon();
  icon.value = defaultFavicon;

  return {
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
    theme: {
      defaultTheme: 'currentTheme',
      themes: {
        currentTheme: {
          dark: defaultTheme.dark,
          colors: defaultTheme.colors
        }
      }
    },
    components,
    directives,
  };
}

// useStyleTag(`@import url(${theme.font.url});`)
// useStyleTag(`body { font-family: ${theme.font.name}, sans-serif; }`)

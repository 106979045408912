<template>
  <v-row justify="center" align="center">
    <v-col cols="12" md="10" lg="6" class="pa-4">
      <v-card color="background" class="px-4 py-6">
        <v-row no-gutters justify="center">
          <v-col cols="12">
            <LoginError :error="error" />
            <v-alert
                density="compact"
                class="text-left"
                v-if="info"
                type="info"
                color="error"
            >
              <span class="text-white">{{ info }}</span>
            </v-alert>
          </v-col>
        </v-row>
        <v-row no-gutters align="center">
          <v-col class="text-center">
            <span class="text-white text-body-1 font-weight-bold">Enter your reset code</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-otp-input
                class="otp"
                length="6"
                v-model="code"
            ></v-otp-input>
          </v-col>
          <v-col cols="12" class="text-center">
            <span class="text-white text-body-1 font-weight-bold">Enter a new password</span>
          </v-col>
          <v-col cols="12">
            <v-form v-model="passwordValid" ref="passwordForm">
              <v-text-field
                  class="custom-field"
                  placeholder="New password"
                  persistent-placeholder
                  variant="outlined"
                  bg-color="#ffffff"
                  :append-icon="value['new'] ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value['new'] = !value['new'])"
                  :type="value['new'] ? 'password' : 'text'"
                  @update:modelValue="$refs.repeatPasswordForm.validate()"
                  v-model="newPassword"
                  :rules="[
                      (v) => (v && v.length >= 6) || 'Password must be at least 6 characters long.',
                      (v) => (v && v.toUpperCase() !== v) || 'Password must contain a lowercase character.',
                      (v) => (v && v.toLowerCase() !== v) || 'Password must contain an uppercase character.',
                      (v) => (v && /\d/.test(v)) || 'Password must contain a number.',
                      (v) => (v && symbols.test(v)) || 'Password must contain a special character.',
                  ]"
                  autocomplete="off"></v-text-field>
            </v-form>
            <v-form v-model="repeatValid" ref="repeatPasswordForm">
              <v-text-field
                  class="custom-field"
                  placeholder="Repeat new password"
                  persistent-placeholder
                  variant="outlined"
                  bg-color="#ffffff"
                  :append-icon="value['repeat'] ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (value['repeat'] = !value['repeat'])"
                  :type="value['repeat'] ? 'password' : 'text'"
                  v-model="repeatedPassword"
                  @update:modelValue="$refs.repeatPasswordForm.validate()"
                  :rules="[
                      (v) => !passwordValid || v === newPassword || 'Passwords do not match.'
                  ]"
                  autocomplete="off"></v-text-field>
            </v-form>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-n4">
          <v-col cols="12" class="text-center">
            <a style="color: #f2243f" @click="resend">Resend reset code?</a>
          </v-col>
          <v-col class="d-flex justify-center">
            <ActionButton width="95" @click="confirmActivationCode" text="Confirm" color="secondary" :loading="loading" :disabled="loading || !passwordValid || !repeatValid || !newPassword || !repeatedPassword"></ActionButton>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Vue, Prop, Watch, toNative} from "vue-facing-decorator";
import ActionButton from "@/components/ActionButton.vue";
import LoginService from "@/services/login.service";
import LoginError from "@/components/LoginError.vue";
@Component({ components: {LoginError, ActionButton} })
class ActivationPrompt extends Vue {
  @Prop() private username!: string;
  @Prop() private info!: string;
  @Prop({default: null}) private queryCode!: string;
  private error = '';
  private code = '';
  private symbols = /^(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])/;
  private newPassword = '';
  private repeatedPassword = '';
  private value = {
    repeat: true,
    new: true,
  };
  private passwordValid = true;
  private repeatValid = true;
  private loading = false;

  @Watch('queryCode', {immediate: true})
  private onQueryParamUpdate() {
    this.code = this.queryCode;
  }

  private async resend() {
    this.$emit('go-to:resend')
  }

  private async confirmActivationCode() {
    this.loading = true;
    try {
      await LoginService.confirmForgotPassword({username: this.username, code: this.code, password: this.newPassword});
      this.$emit('success');
    } catch (err) {
      this.error = (err as Error).message;
    } finally {
      this.loading = false;
    }
  }

}
export default toNative(ActivationPrompt);
</script>

<style scoped>
.otp >>> * {
  border-radius: 8px;
}
</style>
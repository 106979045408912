import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "fill-height embed-container overflow-x-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_app_bar, {
      height: "64",
      flat: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tabs, {
          "bg-color": "background",
          color: "primary",
          modelValue: _ctx.activeApp,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeApp) = $event)),
          class: "menu-tabs",
          disabled: _ctx.loading
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hybridApplications, (app) => {
              return (_openBlock(), _createBlock(_component_v_tab, {
                key: app.applicationId
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(app.tabName || app.name), 1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "disabled"])
      ]),
      _: 1
    }),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_v_progress_linear, {
          key: 0,
          indeterminate: _ctx.loading,
          color: "primary",
          height: "4",
          style: {"z-index":"99"}
        }, null, 8, ["indeterminate"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_card, {
      variant: "flat",
      class: _normalizeClass(["rounded-0 pa-0 fill-height", _ctx.loading ? 'mt-n1' : '']),
      style: {"z-index":"98"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_window, {
          modelValue: _ctx.activeApp,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeApp) = $event)),
          class: "fill-height"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hybridApplications, (app, index) => {
              return (_openBlock(), _createBlock(_component_v_window_item, {
                key: app.applicationId,
                value: index,
                class: "fill-height"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.appViewType(app)), {
                    url: app.url,
                    application: app
                  }, null, 8, ["url", "application"]))
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fill-height embed-container overflow-x-hidden" }
const _hoisted_2 = { class: "fill-height embed-container overflow-x-hidden" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "fill-height embed-container overflow-x-hidden" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_app_bar, {
      height: "64",
      flat: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tabs, {
          "bg-color": "background",
          color: "primary",
          modelValue: _ctx.activeApp,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeApp) = $event)),
          class: "menu-tabs",
          disabled: _ctx.loading
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_tab, { value: "editor" }, {
              default: _withCtx(() => [
                _createTextVNode("Editor")
              ]),
              _: 1
            }),
            _createVNode(_component_v_tab, { value: "dashboard" }, {
              default: _withCtx(() => [
                _createTextVNode("Dashboard")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "disabled"])
      ]),
      _: 1
    }),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_v_progress_linear, {
          key: 0,
          indeterminate: _ctx.loading,
          color: "primary",
          height: "4",
          style: {"z-index":"99"}
        }, null, 8, ["indeterminate"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_card, {
      variant: "flat",
      class: _normalizeClass(["rounded-0 pa-0 fill-height", _ctx.loading ? 'mt-n1' : '']),
      style: {"z-index":"98"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_window, {
          modelValue: _ctx.activeApp,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeApp) = $event)),
          class: "fill-height"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_window_item, {
              value: "editor",
              class: "fill-height"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("iframe", {
                    ref: "frame",
                    class: "embed-container",
                    width: "100%",
                    height: "100%",
                    style: {"border":"none"},
                    src: _ctx.nodeRedEditorUrl
                  }, null, 8, _hoisted_3)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, {
              value: "dashboard",
              class: "fill-height"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("iframe", {
                    ref: "frame",
                    class: "embed-container",
                    width: "100%",
                    height: "100%",
                    style: {"border":"none"},
                    src: _ctx.nodeRedDashboardUrl
                  }, null, 8, _hoisted_5)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}
<script lang="ts">
import { Vue, Component, Prop } from 'vue-facing-decorator';
import { Application, Tenant } from '@/types';
import EmbeddedApplication from '@/views/EmbeddedApplication.vue';
import QuicksightDashboard from '@/components/applications/QuicksightDashboard.vue';
import NodeRedApp from '@/components/applications/NodeRedApp.vue';
import TenantService from '@/services/tenant.service';
import { useTenantStore } from '@/stores/tenantStore';
import ApplicationService from '@/services/application.service';
import { useUserStore } from '@/stores/userStore';
import { useNotificationStore } from '@/stores/notificationStore';

@Component({
  components: { EmbeddedApplication }
})
export default class HybridApp extends Vue {
  @Prop() private application!: Application;
  @Prop() private tenantId!: number;
  private userStore = useUserStore();
  private notificationStore = useNotificationStore();
  private activeApp = 0;
  private loading = false;
  private applications: Application[] = [];

  private async mounted() {
    this.loading = true;
    if (this.tenantId) {
      try {
        await this.fetchApplicationsForHybrid();
        const promises = this.hybridApplications.map(async (app) => {
          if (app.type !== 'nodered') {
            const response = await TenantService.getEmbedUrl(this.tenantId, app.id, true);
            app.url = response.url;
          }
        });

        await Promise.all(promises);
      } finally {
        this.loading = false;
      }
    }
  }

  private async fetchApplicationsForHybrid() {
    if (this.tenantId) {
      this.applications = await TenantService.getApplicationsForHybrid(this.tenantId, this.application.id);
    }
  }

  private get hybridApplications() {
    const apps = [];
    const roleForAccessibleApps = this.userStore.user?.application_roles.filter((ar: { attributes: { hybrid_application_id: number }}) => !!ar.attributes?.hybrid_application_id);
    for (const hybridApp of this.application.details.applications) {
      for (const app of this.applications) {
        if (roleForAccessibleApps && app.id === hybridApp.applicationId && (roleForAccessibleApps.map((r: { application_id: number }) => r.application_id).includes(app.id) || this.userStore.user.is_admin)) {
          const details = {
            ...hybridApp,
            ...app
          };
          apps.push(details);
        }
      }
    }
    return apps;
  }
  private appViewType(activeApp: any) {
    const applicationTypes: Record<string, any> = {
      quicksight: QuicksightDashboard,
      nodered: NodeRedApp,
    }
    if (!this.hybridApplications || !this.hybridApplications.length || !activeApp || !activeApp.type) {
      return null;
    }
    return applicationTypes[activeApp.type!];
  }
}
</script>

<template>
  <div class="fill-height embed-container overflow-x-hidden">
    <v-app-bar height="64" flat>
      <v-tabs bg-color="background" color="primary" v-model="activeApp" class="menu-tabs" :disabled="loading">
        <v-tab v-for="app of hybridApplications" :key="app.applicationId">{{ app.tabName || app.name }}</v-tab>
      </v-tabs>
    </v-app-bar>
    <v-progress-linear v-if="loading" :indeterminate="loading" color="primary" height="4" style="z-index: 99"></v-progress-linear>
    <v-card variant="flat" class="rounded-0 pa-0 fill-height" :class="loading ? 'mt-n1' : ''" style="z-index: 98;">
      <v-window v-model="activeApp" class="fill-height">
        <v-window-item v-for="(app, index) of hybridApplications" :key="app.applicationId" :value="index" class="fill-height">
          <component :is="appViewType(app)" :url="app.url" :application="app"></component>
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<style scoped>

</style>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { useNotificationStore } from '@/stores/notificationStore';
import { useTenantStore } from '@/stores/tenantStore';
import { useDisplay } from 'vuetify';
import { Application, User } from '@/types';
import TenantService from '@/services/tenant.service';
import UserService from '@/services/user.service';
import ApplicationService from '@/services/application.service';

@Component({})
export default class HybridApplicationConfiguration extends Vue {
  @Prop() private currentApp!: Application;
  @Prop() private applicationRolesLookupData!: { [role: string]: { items: { id: string | number, name: string }[], label: string, desc?: string } };
  private currentApplication: Application | null = null;
  private notificationStore = useNotificationStore();
  private tenantStore = useTenantStore();
  private mdAndUp = useDisplay().mdAndUp;
  private logo: File[] = [];
  private searchNames = '';
  private loading = false;
  private editDialog = false;
  private applicationUsers: User[] = [];
  private tenantUsers: User[] = [];
  private currentUser: User | null = null;
  private newUser: User | null = null;
  private newUsers: User[] = [];
  private usersToAdd: User[] = [];
  private usersToRemove: User[] = [];
  private applicationId = '';
  private applicationsForHybrid: Application[] = [];
  private userApplicationsForHybrid: Record<number, Record<number, boolean>> = {};
  private applicationRoleForUser: any = {};
  private appConfigDirtied = false;
  private selectedUsers: User[] = [];
  private attributes: any = {};
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: '', value: 'avatar', sortable: false, width: '60px' },
    { title: 'Name', value: 'full_name', sortable: true },
    { title: 'Email', value: 'email', sortable: true },
    { title: 'Applications', value: 'applications', sortable: true },
    { title: '', value: 'actions', sortable: false },
  ];

  @Watch('currentApp', {immediate: true})
  private onCurrentAppChange() {
    this.currentApplication =  this.currentApp;
  }

  private async mounted() {
    if (this.$route.params.applicationId) {
      this.applicationId = Array.isArray(this.$route.params.applicationId) ? this.$route.params.applicationId[0] : this.$route.params.applicationId;
    }
    await Promise.all([this.fetchApplicationsForHybrid(), this.fetchUsersForApplication(), this.fetchTenantUsers()])
  }

  private async fetchApplicationsForHybrid() {
    if (this.tenantStore.tenantId && this.currentApplication) {
      this.applicationsForHybrid = await TenantService.getApplicationsForHybrid(this.tenantStore.tenantId, this.currentApplication.id);
      for (const a of this.applicationsForHybrid) {
        (a as any).enabled = true;
      }
    }
  }

  private async fetchTenantUsers() {
    if (this.tenantStore.tenantId) {
      this.tenantUsers = await UserService.searchUsers(this.tenantStore.tenantId, 100, 0, '', false, undefined, undefined);
    }
  }

  private async fetchUsersForApplication() {
    if (this.tenantStore.tenantId) {
      this.applicationUsers = await UserService.searchUsers(this.tenantStore.tenantId, 100, 0, '', false, undefined, undefined, Number(this.applicationId));
    }
  }

  private getApplicationRolesForHybridApplications(app: Application) {
    return app?.details?.users?.roles || [];
  }

  private addUserRow() {
    this.newUsers.push({ first_name: '', last_name: '', email: ''});
  }

  private addApplicationAccess() {
    if (this.newUser) {
      this.usersToAdd = this.usersToAdd || []
      this.usersToAdd.push(this.newUser);
      for (const user of this.usersToAdd) {
        user.attributes = this.applicationAttributes.reduce((acc: any, item: any) => {
          acc[item.id] = {
            slug: item.id,
            value: item.default
          };
          return acc;
        }, {});
        for (const a of this.applicationsForHybrid) {
          (a as any).enabled = true;
          for (const user of this.usersToAdd) {
            if (user && user.id) {
              this.userApplicationsForHybrid[user.id] = this.userApplicationsForHybrid[user.id] || {};
              if (typeof this.userApplicationsForHybrid[user.id][a.id] === 'undefined') {
                this.userApplicationsForHybrid[user.id][a.id] = true;
              }
            }
          }
        }
      }

      this.newUser = null;
      this.newUsers = [];
    }
  }
  private removeNewUser(user: User) {
    this.usersToAdd = this.usersToAdd.filter((a) => {
      (a as any).role = null;
      return a.id !== user.id;
    });
  }

  private removeApplicationUser(user: User, andSave = false) {
    this.usersToRemove.push(user);
    this.applicationUsers = this.applicationUsers.filter((a) => a.id !== user.id)

    if (andSave) {
      this.saveUsers();
    }
    this.selectedUsers = [];
  }

  private async saveUsers() {
    this.loading = true;
    try {
      if (this.tenantStore.tenantId) {
        if (this.usersToAdd.length) {
          this.usersToAdd.forEach((u: any) => {
            u.application_roles = [];
            u.application_roles.push({
              user_id: u.id,
              application_id: Number(this.applicationId),
              role: u.role,
              roleContext: u.roleContext || null,
              attributes: u.attributes ? [...Object.values(u?.attributes)] : null
            });
          });
        }
        let users = [...this.usersToAdd, ...this.applicationUsers]
        if (this.usersToRemove.length) {
          users = users.filter((a) => !this.usersToRemove.map((r) => r.id).includes(a.id));
        }
        const subApps: Record<number, number[]> = {};
        for (const user in this.userApplicationsForHybrid) {
          subApps[user] = subApps[user] || [];
          for (const app of this.applicationsForHybrid) {
            if (user && this.userApplicationsForHybrid[user][app.id]) {
              subApps[user].push(Number(app.id));
            }
          }
        }
        const hybridAppAccess = []
        for (const user of this.usersToAdd) {
          if (user.id) {
            hybridAppAccess.push({
              applicationIds: subApps[user.id],
              id: user.id
            });
            if (!subApps[user.id].length) {
              hybridAppAccess.push({
                applicationIds: [],
                id: user.id
              });
            }
          }
        }
        for (const user of this.usersToRemove) {
          hybridAppAccess.push({
            applicationIds: [],
            id: user.id
          });
        }

        await ApplicationService.updateHybridApplicationUserAccess(Number(this.tenantStore.tenantId), { hybridApplicationId: Number(this.applicationId), tenantId: this.tenantStore.tenantId, users: hybridAppAccess});

        if (users.length) {
          const usersDetails: { role: any; user_id: number | undefined; attributes: any, roleContext?: any }[] = users.map((u: any) => {
            const role = u.application_roles?.find((r: { application_id: number }) => r.application_id === Number(this.applicationId))
            return {
              user_id: u.id,
              application_id: Number(this.applicationId),
              role: role?.role,
              roleContext: role?.roleContext || null,
              attributes: role?.attributes || null
            };
          });
          await TenantService.setUsersOnApplication(this.tenantStore.tenantId, Number(this.applicationId), usersDetails);
        } else {
          await TenantService.setUsersOnApplication(this.tenantStore.tenantId, Number(this.applicationId), []);
        }
      }
    } finally {
      this.usersToAdd = [];
      this.usersToRemove = [];
      await this.fetchUsersForApplication();
      this.loading = false;
    }
  }

  private initials(first: string, last: string): string {
    return `${first.charAt(0)} ${last.charAt(0)}`
  }

  private fullName(first: string, last: string): string {
    return `${first} ${last}`
  }

  private filterUsers(value: string, query: any, item: any) {
    return value != null &&
      query != null &&
      item.raw.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
      item.raw.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
      item.raw.email.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  }

  private async saveUser() {
    this.loading = true;
    try {
      if (this.tenantStore.tenantId) {
        const usersDetails: {
          role: any; user_id: number | undefined; attributes: any; application_id?: number, roleContext?: any; applications?: any; }[] = [];
        let oldUsers: any[] = this.applicationUsers;
        oldUsers = oldUsers.filter((u) => u.id !== this.currentUser?.id)
        for (const attr in this.attributes) {
          if (attr in this.attributes) {
            this.applicationRoleForUser.attributes = this.applicationRoleForUser.attributes || [];
            this.applicationRoleForUser.attributes = [{
              slug: attr,
              value: this.attributes[attr]
            }];
          }
        }
        usersDetails.push({
          user_id: this.currentUser!.id,
          application_id: Number(this.applicationId),
          role: this.applicationRoleForUser.name,
          roleContext: this.applicationRoleForUser.context || null,
          attributes: this.applicationRoleForUser.attributes ? [...Object.values(this.applicationRoleForUser.attributes)] : null,
          ...( this.currentApplication?.type === 'hybrid' && { ...{ applications: this.currentApplication.details.applications.map((a: { applicationId: number }) => a.applicationId)}}),
        }, ...oldUsers.map((a) => {
          const role = a.application_roles?.find((r: { application_id: number }) => r.application_id === this.currentApplication!.id)
          return {
            application_id: Number(this.applicationId),
            user_id: a.id,
            role: role?.role,
            roleContext: role?.roleContext || null,
            attributes: role?.attributes ? [...Object.values(role?.attributes)] : null,
          }
        }));

        const subApps: number[] = [];
        for (const app of this.applicationsForHybrid) {
          if ((app as any).enabled) {
            subApps.push(app.id);
          }
        }
        if (this.currentApplication) {
            const users = [{
              applicationIds: subApps,
              id: this.currentUser?.id
            }]
          await ApplicationService.updateHybridApplicationUserAccess(Number(this.tenantStore.tenantId), { hybridApplicationId: Number(this.applicationId), tenantId: this.tenantStore.tenantId, users});
        }
        await TenantService.setUsersOnApplication(this.tenantStore.tenantId, Number(this.applicationId), usersDetails);
      }
    } catch (e) {
      const err = e as Error;
      console.error(err)
    } finally {
      await this.fetchUsersForApplication();
      this.loading = false;
      this.editDialog = false;
    }
  }

  private resetDialog() {
    this.newUsers = [];
    for (const user of this.usersToAdd) {
      (user as any).role = null;
    }
    this.usersToAdd = [];
    this.usersToRemove = [];
    this.userApplicationsForHybrid = {};
  }

  private hasRoleAssigned(): boolean {
    return !(this.applicationRoleForUser.name && this.applicationRoleForUser.context) || !!(
      (
        this.applicationRoleForUser?.name && (
          !this.applicationRolesLookupData[this.applicationRoleForUser.name] || this.applicationRoleForUser.context
        )
      )
    );
  }

  private roleProps(item: any) {
    return {
      title: item.name,
      subtitle: item.description
    }
  }

  private get applicationAttributes() {
    return this.currentApplication?.details?.users?.attributes.map((a: { id: string; name: string; description: string; default: string }) => {
      return {
        ...a,
        default: a.default
      }
    }) || [];
  }


  private get filteredTenantUsers() {
    return this.tenantUsers.filter((tu: any) => {
      return !this.usersToAdd.map((uta) => uta.id).includes(tu.id) && !this.applicationUsers.map((au) => au.id).includes(tu.id);
    })
  }

  private getSubAppState(app: Application) {
    const subApps = this.currentUser?.application_roles?.filter((ar) => !!ar.attributes?.hybrid_application_id) || [];
    return !!subApps.map((su) => su.application_id).includes(app.id);
  }


  private mapConfig(app: Application) {
    this.currentApplication = this.currentUser?.applications?.find((a) => a.id === app.id) || app;
    const applicationRoles = this.currentUser?.application_roles?.find((r) => r.application_id === app.id);
    this.applicationRoleForUser.name = applicationRoles?.role;
    this.applicationRoleForUser.context = applicationRoles?.roleContext;

    const attrs = this.applicationAttributes;
    for (const attr of attrs) {
      if (attr.default === 'true') {
        attr.default = true;
      }
      this.roleAttributes(attr);
    }
    for (const a of this.applicationsForHybrid) {
      (a as any).enabled = this.getSubAppState(a);
    }
  }

  private roleAttributes(attribute: any) {
    const roleForCurrentApp = this.currentUser?.application_roles?.find((r) => r.application_id === this.currentApplication?.id);
    if (this.currentApplication) {
      const attrs = this.applicationAttributes;
      let value;
      if (roleForCurrentApp?.attributes?.length) {
        if (attribute) {
          const currentAttribute = roleForCurrentApp?.attributes.find((a: any) => a.slug === attribute.id);
          if (currentAttribute) {
            value = typeof currentAttribute.value === 'string' ? currentAttribute.value === 'true' : currentAttribute.value;
          } else {
            value = attrs.find((a: any) => a.id === attribute.id)?.default === 'true';
          }
        }
      } else {
        value = attrs.find((a: any) => a.id === attribute.id)?.default;
      }
      this.attributes[attribute.id] = value;
    }
    this.applicationRoleForUser.name = roleForCurrentApp?.role;
    this.applicationRoleForUser.context = roleForCurrentApp?.roleContext;
  }

  private getHybridAppsForUser(user: User) {
    const attrs = user?.application_roles?.filter((ar: { attributes: { hybrid_application_id: number }}) => !!ar.attributes?.hybrid_application_id);
    return this.applicationsForHybrid.filter((a) => attrs?.map((h) => h.application_id).includes(a.id))
  }
}
</script>

<template>
  <div>
    <v-row class="pt-2 mx-4" justify="space-between" align="center" :dense="true">
      <v-col cols="12" md="6">
        <v-row align="center" :dense="true">
          <v-col cols="12">
            <v-row dense>
              <v-col cols="4" md="auto">
                <v-dialog width="500" @update:modelValue="resetDialog()">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" width="230" variant="tonal" color="primary" @click="mapConfig(currentApplication)"><v-icon start icon="mdi-account-plus"></v-icon>
                      {{ mdAndUp ? 'Add application user' : 'Add' }}
                    </v-btn>
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-card>
                      <template v-slot:title>
                        <v-row no-gutters justify="space-between">
                          <v-col cols="auto">
                            Add application users
                          </v-col>
                          <v-col cols="auto">
                            <v-btn size="small" color="primary" variant="plain" @click="addUserRow"><v-icon start icon="mdi-plus-circle"></v-icon> Add </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <p class="text-disabled mb-4 mt-2">In a hybrid application, individual applications can be toggled per-user. By default, all applications are toggled on.</p>
                              </v-col>
                              <v-col cols="12">
                                <v-list>
                                  <v-list-item v-for="user of usersToAdd" :key="user.email" rounded class="my-2 px-0">
                                    <v-expansion-panels model-value="0">
                                      <v-expansion-panel bg-color="rgba(0,0,0,0.05)">
                                        <v-expansion-panel-title class="py-0 px-2" static hide-actions>
                                          <template v-slot:default="{ collapseIcon, expandIcon, expanded }">
                                            <v-row no-gutters justify="space-between" align="center">
                                              <v-col cols="1">
                                                <span v-if="expanded"><v-icon>{{ collapseIcon }}</v-icon></span>
                                                <span v-else><v-icon>{{ expandIcon }}</v-icon></span>
                                              </v-col>
                                              <v-col cols="10">
                                                <v-list-item-title>{{ fullName(user.first_name, user.last_name) }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                                              </v-col>
                                              <v-col cols="1">
                                                <v-list-item-action>
                                                  <v-btn icon variant="plain" color="primary" size="small" @click="removeNewUser(user)"><v-icon icon="mdi-delete" size="large"></v-icon></v-btn>
                                                </v-list-item-action>
                                              </v-col>
                                            </v-row>
                                          </template>
                                        </v-expansion-panel-title>
                                        <v-expansion-panel-text class="mt-1">
                                          <v-row v-for="app of applicationsForHybrid" :key="app.id">
                                            <v-col cols="12">
                                              <v-row no-gutters justify="space-between" align="center">
                                                <v-col>
                                                  <v-list-item-title>{{ currentApplication?.details?.applications?.find((a) => a.applicationId === app.id)?.tabName || app.name }}</v-list-item-title>
                                                  <v-list-item-subtitle>Enabled for this user</v-list-item-subtitle>
                                                </v-col>
                                                <v-col cols="2">
                                                  <v-switch hide-details v-if="app" density="compact" v-model="userApplicationsForHybrid[user.id][app.id]" @update:model-value="appConfigDirtied = true" color="primary"></v-switch>
                                                </v-col>
                                              </v-row>
                                            </v-col>
                                            <v-col cols="12" v-if="applicationAttributes.length">
                                              <p class="text-disabled mb-4">Configure application attributes for this user</p>
                                              <v-row class="ml-2">
                                                <v-col cols="12" v-for="attribute of applicationAttributes" :key="attribute.id">
                                                  <v-row no-gutters justify="space-between">
                                                    <v-col>
                                                      <v-list-item-title>{{ attribute.name }}</v-list-item-title>
                                                      <v-list-item-subtitle>{{ attribute.description }}</v-list-item-subtitle>
                                                    </v-col>
                                                    <v-col cols="2">
                                                      <v-switch hide-details v-if="attribute.type === 'boolean'" density="compact" v-model="user.attributes[attribute.id].value" @update:model-value="appConfigDirtied = true" color="primary"></v-switch>
                                                    </v-col>
                                                  </v-row>
                                                </v-col>
                                              </v-row>
                                            </v-col>
                                          </v-row>
                                        </v-expansion-panel-text>
                                      </v-expansion-panel>
                                    </v-expansion-panels>
                                  </v-list-item>
                                  <v-list-item
                                    class="mb-1 px-0 pt-0"
                                    v-for="user of newUsers"
                                    :key="user.email"
                                  >

                                    <v-combobox
                                      class="app-combobox"
                                      clearable
                                      :items="filteredTenantUsers"
                                      item-title="name"
                                      hide-details
                                      density="comfortable"
                                      placeholder="Search users"
                                      return-object
                                      :menu-props="{ maxWidth: 452 }"
                                    >
                                      <template v-slot:selection="{ item }">
                                        <div class="d-flex flex-column">
                                          <v-list-item-title>{{ fullName(item.raw.first_name, item.raw.last_name) }}</v-list-item-title>
                                          <v-list-item-subtitle>{{ item.raw.email }}</v-list-item-subtitle>
                                        </div>
                                      </template>
                                      <template v-slot:item="{ item }">
                                        <div class="d-flex flex-column">
                                          <v-list-item @click="newUser = item.raw; addApplicationAccess()">
                                            <v-list-item-title>{{ fullName(item.raw.first_name, item.raw.last_name) }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.raw.email }}</v-list-item-subtitle>
                                          </v-list-item>
                                        </div>
                                      </template>
                                    </v-combobox>
                                  </v-list-item>
                                </v-list>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          :loading="loading"
                          :disabled="!usersToAdd.length || loading"
                          color="primary"
                          @click="saveUsers(); isActive.value = false;"
                        >
                          Add users
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
              <v-col cols="4" md="auto">
                <v-dialog width="500">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" width="230" variant="tonal" color="primary" :disabled="!selectedUsers.length"><v-icon start icon="mdi-account-minus"></v-icon>
                      {{ mdAndUp ? 'Remove application user' : 'Remove' }}
                    </v-btn>
                  </template>
                  <template v-slot:default="{ isActive }">
                    <v-card width="500">
                      <v-card-title>Remove user from application</v-card-title>
                      <v-card-text>
                        Are you sure you want to remove this user from this application? The user will no longer have access to the application.
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="isActive.value = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          :loading="loading"
                          :disabled="loading"
                          color="primary"
                          @click="removeApplicationUser(selectedUsers[0], true); isActive.value = false">
                          Remove
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12" style="margin-top: -46px;">
            <v-text-field hide-details density="compact" placeholder="Search application users" append-inner-icon="mdi-magnify" v-model="searchNames"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
      :items="applicationUsers"
      :headers="headers"
      v-model="selectedUsers"
      fixed-header
      show-select
      return-object
      :custom-filter="filterUsers"
      :search="searchNames"
      height="calc(100vh - 180px)"
      items-per-page="25"
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <v-avatar color="secondary">{{ initials(item.first_name, item.last_name) }}</v-avatar>
      </template>
      <template v-slot:[`item.full_name`]="{ item }">
        {{ fullName(item.first_name, item.last_name) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.status === 'active'" class="d-flex">
          <v-icon start icon="mdi-circle" color="success"></v-icon>
          Active
        </div>
        <div v-if="item.status === 'pending'" class="d-flex">
          <v-icon start icon="mdi-circle" color="grey"></v-icon>
          Pending
        </div>
      </template>
      <template v-slot:[`item.applications`]="{item}">
        <div class="d-flex">
          <v-tooltip v-for="app of getHybridAppsForUser(item)" :key="app.id" :text="currentApplication?.details?.applications.find((a) => a.applicationId === app.id).tabName || app.name" location="bottom" open-delay="200">
            <template v-slot:activator="{ props }">
              <v-avatar v-bind="props" size="32" color="secondary" rounded class="mx-1">{{ currentApplication?.details?.applications.find((a) => a.applicationId === app.id).tabName.charAt(0) || app.name.charAt(0) }}</v-avatar>
            </template>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn size="small" variant="tonal" color="primary" width="80" @click="currentUser = {...item}; mapConfig(currentApplication); editDialog = true;">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog width="500" v-model="editDialog" @update:model-value="appConfigDirtied = false;" id="edit-dialog">
      <template v-slot:default>
        <v-card title="Edit user">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p class="text-disabled">In a hybrid application, individual applications can be toggled per-user. By default, all applications are toggled on.</p>
              </v-col>
              <v-col cols="12" v-for="app of applicationsForHybrid" :key="app.id">
                <v-row no-gutters justify="space-between" align="center">
                  <v-col>
                    <v-list-item-title>{{ currentApplication?.details?.applications?.find((a) => a.applicationId === app.id)?.tabName || app.name }}</v-list-item-title>
                    <v-list-item-subtitle>Enabled for this user</v-list-item-subtitle>
                  </v-col>
                  <v-col cols="2">
                    <v-switch hide-details v-if="app" density="compact" v-model="app.enabled" @update:model-value="appConfigDirtied = true" color="primary"></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="applicationAttributes.length">
                <p class="text-disabled mb-4">Configure application attributes for this user</p>
                <v-row class="ml-2">
                  <v-col cols="12" v-for="attribute of applicationAttributes" :key="attribute.id">
                    <v-row no-gutters justify="space-between">
                      <v-col>
                        <v-list-item-title>{{ attribute.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ attribute.description }}</v-list-item-subtitle>
                      </v-col>
                      <v-col cols="2">
                        <v-switch hide-details v-if="attribute.type === 'boolean'" density="compact" v-model="attributes[attribute.id]" @update:model-value="appConfigDirtied = true" color="primary"></v-switch>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              :disabled="loading || !hasRoleAssigned() || !appConfigDirtied"
              color="primary"
              @click="saveUser(); appConfigDirtied = false;"
            >
              Save user
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<style scoped>

</style>
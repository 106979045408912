<script lang="ts">
import { Vue, Component, Watch } from 'vue-facing-decorator';
import {useDisplay} from "vuetify";
import ApplicationService from "@/services/application.service";
import { Application, Tenant } from '@/types';
import { VAceEditor } from 'vue3-ace-editor';
import 'ace-builds/webpack-resolver';
//eslint-disable-next-line
const ace = require('ace-builds')
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import {useNotificationStore} from "@/stores/notificationStore";
import TenantService from '@/services/tenant.service';
import draggable from 'vuedraggable'
import { noderedInstances } from '@/assets/nodered.instances';

@Component({ components: { VAceEditor, draggable }})
export default class ApplicationsManagement extends Vue {
  private notificationStore = useNotificationStore();
  private noderedInstances = noderedInstances;
  private noderedInstance: { id: number; domainName: string; name: string; } | null = null;
  private applications: Application[] = [];
  private tenants: Tenant[] = [];
  private selectedApplications: Application[] = [];
  private draggedList: any[] = [];
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: 'Name', value: 'name', sortable: true, width: '20%' },
    { title: 'Type', value: 'type', sortable: true, width: '20%' },
    { title: 'description', value: 'description', sortable: true, width: '35%' },
    { title: '', value: 'actions', sortable: false },
  ];
  private mdAndUp = useDisplay().mdAndUp;
  private loading = false;
  private currentApplication: Partial<Application> | null = null;
  private editDialog = false;
  private registerDialog = false;
  private removeDialog = false;
  private app = {
    name: '',
    description: '',
    type: '',
    details: {  }
  }
  private tab: 'details' | 'config' = 'details';
  private disabled = '';
  private hybridApps: Record<string, any>[] = [];
  private appTypes: Array<{ name: string; type: string; details?: Record<string, string | number[] | number>}> = [
    {
      name: 'External',
      type: 'external',
      details: {}
    },
    {
      name: 'Quicksight',
      type: 'quicksight',
      details: {
        "dashboardId":"",
      }
    },
    {
      name: 'Node-RED',
      type: 'nodered',
      details: {
        "instanceNumber": 0,
        "dashboardSubPath": "",
      }
    },
    {
      name: 'Hybrid',
      type: 'hybrid',
      details: {
        "applications": [],
      }
    },
  ]

  @Watch('app', {deep: true, immediate: true})
  @Watch('currentApplication', {deep: true, immediate: true})
  private onAppConfigChange() {
    this.draggedList = (this.app?.details as any)?.applications || this.currentApplication?.details?.applications
  }

  private async mounted() {
    this.loading = true;
    try {
      await Promise.all([this.fetchApplications(), this.fetchTenants()]);
    } finally {
      this.loading = false;
    }
  }

  private async fetchApplications() {
    this.applications = await ApplicationService.getAllApplications();
  }

  private async fetchTenants() {
    this.tenants = await TenantService.getTenants();
  }

  private async registerApp() {
    this.loading = true;
    try {
      if (Object.keys(this.app.details).length > 0) {
        this.app.details = JSON.stringify(this.app.details);
      } else {
        this.app.details = JSON.stringify(this.appTypes.find((t) => t.type === this.app.type)?.details);
      }
      await ApplicationService.registerApplication(this.app);
    } finally {
      await this.fetchApplications();
      this.app = { name: '', type: '', description: '', details: {}};
      this.loading = false;
    }
  }

  private updateEditor(text: string) {
    const isJson = (str: string) => {
      try {
        const json = JSON.parse(str);
        return typeof json === 'object' && json !== str || false
      } catch (e) {
        return false;
      }
    }
    if (isJson(text)) {
      if (this.currentApplication) {
        this.currentApplication.details = JSON.parse(text);
      }
      if (this.app) {
        this.app.details = JSON.parse(text)
      }
    } else {
      this.disabled = 'Invalid JSON'
    }
  }

  private randomColor(str: string) {
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += value.toString(16).padStart(2, '0')
    }
    return color
  }

  private truncate(text: string) {
    return text.substring(0, 100) + "...";
  }

  private editApplication(item: Application) {
    this.currentApplication = {...item};

    if (this.currentApplication.type === 'nodered') {
      const currentInstance = this.noderedInstances.find((nr: { id: number }) => nr.id === this.currentApplication?.details.instanceNumber);
      this.noderedInstance = currentInstance || this.noderedInstance;
    }

    if (this.currentApplication.type === 'hybrid') {
      const currentAppHybridApps = this.currentApplication?.details.applications.map((hybridApp: { applicationId: number }) => hybridApp.applicationId);
      this.hybridApps = this.applications.filter((a) => currentAppHybridApps.includes(a.id));
    }

    this.editDialog = true;
  }

  private async saveApplication() {
    this.loading = true;
    if (this.currentApplication) {
      try {
        await ApplicationService.updateApplication(this.currentApplication);
        await this.fetchApplications();
      } catch (e) {
        const err = e as Error;
        console.error(err)
      } finally {
        this.loading = false;
        this.editDialog = false;
      }
    }
  }

  private async removeApplication() {
    this.loading = true;
    try {
      const app = await ApplicationService.removeApplication(this.selectedApplications[0].id)
      this.removeDialog = false;
    } catch (e) {
      const err = e as Error;
      console.error(err);
      this.disabled = err.message;
    } finally {
      this.loading = false;
      this.selectedApplications = [];
      await this.fetchApplications();
    }
  }

  private updateConfiguration(currentApplication: Application) {
    if (currentApplication) {
      if (currentApplication.type === 'nodered') {
        currentApplication.details = {
          instanceNumber: this.noderedInstance?.id,
          dashboardSubPath: '',
        }
      }
      if (currentApplication.type === 'hybrid') {
        currentApplication.details.applications = [];
        for (const app of this.hybridApps) {
          currentApplication?.details.applications.push({
            applicationId: app.id,
            name: app.name,
            tabName: ''
          });
        }
      }
    }
  }

  private get hybridAvailableApps() {
    return this.applications.filter((a) => a.type && ['quicksight', 'nodered'].includes(a.type));
  }

  private get appIsAssignedToTenant() {
    return this.tenants.some((t: Tenant) => t.applications?.find((a: Application) => a.id === this.currentApplication?.id))
  }

  private get appIsHybrid() {
    return this.currentApplication?.type === 'hybrid';
  }

  private get subAppExistsInHybrid() {
    return this.applications.some((a) => a && a.details && a.details.applications && a.details.applications.map((subApp: { applicationId: number }) => subApp.applicationId).includes(this.currentApplication?.id));
  }
}
</script>

<template>
  <div>
    <v-row class="pt-2 mx-4" justify="space-between" align="center" dense>
      <v-col cols="12" md="6">
        <v-row align="center" dense>
          <v-col cols="12">
            <h2>Application management</h2>
          </v-col>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="4" md="auto">
                <v-dialog v-model="registerDialog" width="500" @update:modelValue="app = { name: '', type: '', description: '', details: {}}; tab = 'details'; hybridApps = []; noderedInstance = null">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" width="220" variant="tonal" color="primary">
                      <v-icon icon="mdi-plus-box" start></v-icon>
                      {{ mdAndUp ? 'Register application' : 'Register' }}
                    </v-btn>
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-card title="Register an application">
                      <v-tabs
                          v-model="tab"
                          :disabled="!app.type"
                      >
                        <v-tab value="details">Details</v-tab>
                        <v-tab value="config">Configuration</v-tab>
                      </v-tabs>
                      <v-card-text>
                        <v-window v-model="tab">
                          <v-window-item value="details">
                            <v-form>
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-text-field hide-details density="comfortable" label="Name" v-model="app.name"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-select hide-details density="comfortable" label="Type of application" v-model="app.type" :items="appTypes" item-title="name" item-value="type"></v-select>
                                </v-col>
                                <v-col cols="12" v-if="app.type === 'nodered'">
                                  <v-select hide-details density="comfortable" label="Node-RED instance" return-object v-model="noderedInstance" :items="noderedInstances" item-title="name" item-value="id" @update:model-value="updateConfiguration(app)"></v-select>
                                </v-col>
                                <v-col cols="12" v-if="app.type === 'hybrid'">
                                  <v-select multiple hide-details density="comfortable" label="Applications to group" return-object v-model="hybridApps" :items="hybridAvailableApps" item-title="name" item-value="id" @update:model-value="updateConfiguration(app)"></v-select>
                                </v-col>
                                <v-col cols="12" v-if="app.type === 'hybrid' && !!hybridApps?.length && !disabled.length && app?.details?.applications?.length">
                                  <v-card color="#3c3c3c" class="pa-4" variant="flat" style="border-bottom: 1px solid rgb(133, 133, 133); border-radius: 0" rounded="t">
                                    <p class="text-medium-emphasis text-caption">Tab order</p>
                                    <v-list bg-color="transparent">
                                      <draggable
                                        item-key="applicationId"
                                        :list="draggedList"
                                      >
                                        <template #item="{ element, index }">
                                          <v-list-item border class="mt-1" rounded color="rgba(0,0,0,0.05)">
                                            <template v-slot:prepend>
                                              <div class="d-flex">
                                                <v-icon v-if="draggedList.length > 1" style="cursor: pointer" class="mr-2" color="primary">mdi-drag</v-icon>
                                              </div>
                                            </template>
                                            <v-list-item-title>
                                              {{ element.name }}
                                            </v-list-item-title>
                                            <template v-slot:append>
                                              <div class="d-flex">
                                                <v-avatar rounded size="24" class="mr-2" dark color="primary" variant="tonal"><span class="text-caption font-weight-bold">{{index + 1}}</span></v-avatar>
                                              </div>
                                            </template>
                                          </v-list-item>
                                        </template>
                                      </draggable>
                                    </v-list>
                                    <p class="text-caption" v-if="draggedList.length > 1"><v-icon size="x-small" start>mdi-information-outline</v-icon>Drag the list items above to order the tabs in your hybrid application</p>
                                  </v-card>
                                </v-col>
                                <v-col cols="12">
                                  <v-textarea hide-details density="comfortable" label="Description" v-model="app.description"></v-textarea>
                                </v-col>
                              </v-row>
                            </v-form>
                          </v-window-item>
                          <v-window-item value="config">
                            <v-ace-editor
                                :value="Object.keys(app.details).length ? JSON.stringify(app.details, null, 2) : appTypes.find((t) => t.type === app.type) ? JSON.stringify(appTypes.find((t) => t.type === app.type).details, null, 2) : '{}'"
                                @update:value="disabled = ''; updateEditor($event);"
                                lang="json"
                                theme="chrome"
                                style="height: 310px" />
                          </v-window-item>
                        </v-window>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-tooltip :text="disabled" location="bottom" open-delay="200">
                          <template v-slot:activator="{ props }">
                            <div
                                v-if="disabled.length"
                                v-bind="props"
                            >
                              <v-btn
                                  :loading="loading"
                                  :disabled="!app.name || !app.type || loading || !!disabled.length"
                                  color="primary"
                                  @click="registerApp(); isActive.value = false;"
                              >
                                Register application
                              </v-btn>
                            </div>
                          </template>
                        </v-tooltip>
                        <v-btn
                            v-if="!disabled.length"
                            :loading="loading"
                            :disabled="!app.name || !app.type || loading || (!hybridApps.length && app.type === 'hybrid') || (!noderedInstance?.id && app.type === 'nodered')"
                            color="primary"
                            @click="registerApp(); isActive.value = false;"
                        >
                          Register application
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
              <v-col cols="4" md="auto">
                <v-dialog width="500" v-model="removeDialog" @update:modelValue="disabled = ''">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" width="220" variant="tonal" :disabled="!selectedApplications.length" color="primary" @click="currentApplication = selectedApplications[0]">
                      <v-icon icon="mdi-close-box" start></v-icon>
                      {{ mdAndUp ? 'Remove application' : 'Remove' }}
                    </v-btn>
                  </template>
                  <template v-slot:default>
                    <v-card max-width="460" :loading="loading">
                      <v-card-title>Remove application</v-card-title>
                      <v-card-text>
                        <div v-if="!!disabled.length">
                          <p>
                            <span class="text-error">This application cannot be removed, because it has active tenants assigned to it. You can unassign the application from the tenants, and try again.</span>
                          </p>
                          <p class="text-center mt-4"><v-btn color="primary" variant="tonal" :to="{ name: 'tenants' }">Go to tenant management</v-btn></p>
                        </div>
                        <div v-if="subAppExistsInHybrid">
                          <p>
                            <span class="text-error">This application cannot be removed, because it is a sub-application within a hybrid application. Remove it from any hybrid applications, and try again.</span>
                          </p>
                        </div>
                        <span v-else>
                          Are you sure you want to remove this application?
                        </span>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="removeDialog = false">
                          Cancel
                        </v-btn>
                        <v-btn
                            :loading="loading"
                            :disabled="loading || !!disabled.length || subAppExistsInHybrid"
                            color="error"
                            @click="removeApplication">
                          Remove
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-data-table
        :loading="loading"
        color="primary"
        :items="applications"
        :headers="headers"
        show-select
        v-model="selectedApplications"
        select-strategy="single"
        items-per-page="25"
        return-object
        height="calc(100vh - 180px)"
        fixed-header
    >
      <template v-slot:[`item.type`]="{ item }">
        <span class="text-capitalize">{{ appTypes.find((t) => t.type === item.type).name }}</span>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <span v-if="item.description.length > 70">{{ truncate(item.description) }}</span>
        <span v-else>{{ item.description }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn width="80" size="small" variant="tonal" color="primary" @click="editApplication(item);">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog width="500" v-model="editDialog" @update:modelValue="tab = 'details'; hybridApps = []; noderedInstance = null">
      <template v-slot:default="{ isActive }">
        <v-card title="Edit application">
          <v-tabs
              v-model="tab"
          >
            <v-tab value="details">Details</v-tab>
            <v-tab value="config">Configuration</v-tab>
          </v-tabs>
          <v-card-text>
            <v-window v-model="tab">
              <v-window-item value="details">
                <v-form>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field hide-details density="comfortable" label="Name" v-model="currentApplication.name"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select class="text-capitalize" disabled hide-details density="comfortable" label="Type of application" v-model="currentApplication.type" item-title="name" item-value="type"></v-select>
                    </v-col>
                    <v-col cols="12" v-if="currentApplication.type === 'nodered'">
                      <v-select density="comfortable" label="Node-RED instance" return-object v-model="noderedInstance" :items="noderedInstances" item-title="name" item-value="id" @update:model-value="updateConfiguration(currentApplication)" :hide-details="!appIsAssignedToTenant" :persistent-hint="true" :hint="appIsAssignedToTenant ? 'This app cannot be modified because it is assigned to a tenant' : ''" :disabled="appIsAssignedToTenant" ></v-select>
                    </v-col>
                    <v-col cols="12" v-if="currentApplication.type === 'hybrid'">
                      <v-select :hide-details="!appIsAssignedToTenant" :persistent-hint="true" :hint="appIsAssignedToTenant ? 'This app cannot be modified because it is assigned to a tenant' : ''" :disabled="appIsAssignedToTenant" multiple density="comfortable" label="Applications to group" return-object v-model="hybridApps" :items="hybridAvailableApps" item-title="name" item-value="id" @update:model-value="updateConfiguration(currentApplication)"></v-select>
                    </v-col>
                    <v-col cols="12" v-if="currentApplication.type === 'hybrid' && !!hybridApps?.length && !disabled.length && currentApplication?.details?.applications?.length">
                      <v-card color="#3c3c3c" class="px-4 py-2" variant="flat" style="border-bottom: 1px solid rgb(133, 133, 133); border-radius: 0" rounded="t">
                        <p class="text-medium-emphasis text-caption">Tab order</p>
                        <v-list bg-color="transparent">
                          <draggable
                            item-key="applicationId"
                            :list="draggedList"
                          >
                            <template #item="{ element, index }">
                              <v-list-item border class="mt-1" rounded color="rgba(0,0,0,0.05)">
                                <template v-slot:prepend>
                                  <div class="d-flex">
                                    <v-icon v-if="draggedList.length > 1" style="cursor: pointer" class="mr-2" color="primary">mdi-drag</v-icon>
                                  </div>
                                </template>
                                <v-list-item-title>
                                  {{ element.name }}
                                </v-list-item-title>
                                <template v-slot:append>
                                  <div class="d-flex">
                                    <v-avatar rounded size="24" class="mr-2" dark color="primary" variant="tonal"><span class="text-caption font-weight-bold">{{index + 1}}</span></v-avatar>
                                  </div>
                                </template>
                              </v-list-item>
                            </template>
                          </draggable>
                        </v-list>
                        <p class="text-caption" v-if="draggedList.length > 1"><v-icon size="x-small" start>mdi-information-outline</v-icon>Drag the list items above to order the tabs in your hybrid application</p>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea hide-details density="comfortable" label="Description" v-model="currentApplication.description"></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-window-item>
              <v-window-item value="config">
                <v-card flat color="transparent" :disabled="appIsAssignedToTenant && appIsHybrid">
                  <v-ace-editor
                    :readonly="appIsAssignedToTenant && appIsHybrid"
                    :value="JSON.stringify(currentApplication.details, null, 2) || '{}'"
                    @update:value="disabled = ''; updateEditor($event);"
                    lang="json"
                    theme="chrome"
                    style="height: 310px" />
                </v-card>
              </v-window-item>
            </v-window>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip :text="disabled" location="bottom" open-delay="200">
              <template v-slot:activator="{ props }">
                <div
                    v-if="disabled.length"
                    v-bind="props"
                >
                  <v-btn
                      :loading="loading"
                      :disabled="!currentApplication.name || !!disabled.length"
                      color="primary"
                  >
                    Save application
                  </v-btn>
                </div>
              </template>
            </v-tooltip>
            <v-btn
                v-if="!disabled.length"
                :loading="loading"
                :disabled="!currentApplication.name || loading"
                color="primary"
                @click="saveApplication(); isActive.value = false;"
            >
              Save application
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<style>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>

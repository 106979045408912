import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "font-weight-light" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_StandaloneApplicationConfiguration = _resolveComponent("StandaloneApplicationConfiguration")!
  const _component_HybridApplicationConfiguration = _resolveComponent("HybridApplicationConfiguration")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_row, {
      class: "pt-2 mx-4",
      justify: "space-between",
      align: "center",
      dense: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          md: "6"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              align: "center",
              dense: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, {
                      "no-gutters": "",
                      align: "center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "auto" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, {
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'applications' }))),
                              class: "mr-4",
                              size: "28",
                              icon: "",
                              color: "primary",
                              variant: "tonal"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode("mdi-arrow-left")
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, null, {
                          default: _withCtx(() => [
                            _createElementVNode("h2", null, [
                              _createTextVNode("Application configuration "),
                              _createVNode(_component_v_chip, {
                                variant: "tonal",
                                color: "primary",
                                label: "",
                                class: "ml-md-4"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.currentApplication ? _ctx.currentApplication.name : ''), 1)
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.currentApplication)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.currentApplication.type !== 'hybrid')
            ? (_openBlock(), _createBlock(_component_StandaloneApplicationConfiguration, {
                key: 0,
                currentApp: _ctx.currentApplication,
                applicationRolesLookupData: _ctx.applicationRolesLookupData
              }, null, 8, ["currentApp", "applicationRolesLookupData"]))
            : (_openBlock(), _createBlock(_component_HybridApplicationConfiguration, {
                key: 1,
                currentApp: _ctx.currentApplication,
                applicationRolesLookupData: _ctx.applicationRolesLookupData
              }, null, 8, ["currentApp", "applicationRolesLookupData"]))
        ]))
      : _createCommentVNode("", true)
  ]))
}
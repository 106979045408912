
export default class Transformation {
  public static filterMapToQueryString(filters: Record<string, Record<string, boolean>>): string {
    return Object.entries(filters).map(([key, filter]) => {
      return isNaN(Number(filter)) ?
        `${key}=[${ typeof filter === 'object' ? Object.keys(filter).map((filterValue) => filterValue) : filter}]` :
        `${key}=[${Object.keys(filter).map((filterValue) => Number(filterValue))}]`;

    }).join('&');
  }
}

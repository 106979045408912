<script lang="ts">
import { Component, Vue, Watch } from 'vue-facing-decorator';
import { Application, Role, User } from '@/types';
import { useDisplay } from 'vuetify';
import UserService from '@/services/user.service';
import { useUserStore } from '@/stores/userStore';
import ApplicationService from '@/services/application.service';
import { useTenantStore } from '@/stores/tenantStore';
import TenantService from '@/services/tenant.service';
import { useNotificationStore } from '@/stores/notificationStore';

@Component({})
export default class UserManagement extends Vue {
  private notificationStore = useNotificationStore();
  private tenantStore = useTenantStore();
  private userStore = useUserStore();
  private mdAndUp = useDisplay().mdAndUp;
  private searchNames = '';
  private users: User[] = [];
  private roles: Role[] = [];
  private selectedUsers: User[] = [];
  private applicationsToAdd: Application[] = [];
  private applicationRoleForUser: any = {};
  private applicationRoleContextForUser: any = {};
  private applicationRolesLookupData: { [appId: number]: { [role: string]: { items: { id: string | number, name: string }[], label: string, desc?: string } } } = {};
  private applicationsToRemove: Application[] = [];
  private loading = false;
  private editDialog = false;
  private tab: 'details' | 'config' = 'details';
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: '', value: 'avatar', sortable: false, width: '60px' },
    { title: 'Name', value: 'full_name', sortable: true },
    { title: 'Email', value: 'email', sortable: true },
    { title: 'Role', value: 'role', sortable: true },
    { title: 'Status', value: 'status', sortable: true },
    { title: 'Applications', value: 'applications', sortable: false },
    { title: '', value: 'actions', sortable: false },
  ];
  private user: { first_name: string; last_name: string; email: string; role?: number; applications: Application[] } = {
    first_name: '',
    last_name: '',
    email: '',
    role: undefined,
    applications: []
  }
  private currentUser: User | null = null;
  private newApp: Application | null = null;
  private newApps: Partial<Application>[] = [];
  private tenantApplications: Application[] = [];
  private applicationsForHybrid: Application[] = [];
  private currentApplication: Application | undefined | null = null;
  private attributes: {[key: string]: any} = {};
  private warningDialog = false;
  private selectedRole: {[key: string]: any} = {};
  private appConfigDirtied = false;
  private searchTimeout: any = {};
  private applicationRoles: any = [];
  private selectedAppRole: {[key: number]: { name?: string | null, id?: string | number | null }} = {};
  private selectedAppRoleContext: {[key: number]: { name?: string | null, id?: string | number | null }} = {};
  private opened: any = [];
  private showRemoveDialog: {[key: number]: boolean} = {};
  private roleLookupDataLoading = false;

  private async mounted() {
    if (this.tenantStore.tenantId) {
      const [users, roles, tenantApplications] = await Promise.all([
        UserService.searchUsers(this.tenantStore.tenantId),
        UserService.getRoles(),
        ApplicationService.getApplicationsForTenant(this.tenantStore.tenantId)
      ]);

      this.users = users;
      this.roles = roles;
      this.tenantApplications = tenantApplications;
    }
  }

  private async fetchApplicationsForHybrid(app: Application) {
    if (this.tenantStore.tenantId && app) {
      this.applicationsForHybrid = await TenantService.getApplicationsForHybrid(this.tenantStore.tenantId, app.id);
      for (const a of this.applicationsForHybrid) {
        (a as any).enabled = this.getSubAppState(a);
        if (this.applicationsToAdd.map((ata) => ata.id).includes(app.id)) {
          (a as any).enabled = true;
        }
      }
    }
  }

  private get filteredTenantApplications() {
    return this.tenantApplications.filter((ta) => !this.currentUser?.applications?.map((cua) => cua.id).includes(ta.id) && !this.applicationsToAdd.map((ata) => ata.id).includes(ta.id))
  }

  private getSubAppState(app: Application) {
    const subApps = this.currentUser?.application_roles?.filter((ar) => !!ar.attributes?.hybrid_application_id) || [];
    return !!subApps.map((su) => su.application_id).includes(app.id);
  }

  private get userApplications() {
    return this.tenantApplications.filter((ta) => this.currentUser?.applications?.map((cua) => cua.id).includes(ta.id));
  }

  private async mapConfig(app: Application) {
    if (app.type === 'hybrid') {
      await this.fetchApplicationsForHybrid(app);
    }
    this.currentApplication = this.currentUser?.applications?.find((a) => a.id === app.id);

    const currentUserApplicationRole = this.currentUser?.application_roles?.find((r) => r.application_id === app.id);
    this.selectedAppRole[app.id] = {name: currentUserApplicationRole?.role, id: currentUserApplicationRole?.role};
    if (this.currentApplication && currentUserApplicationRole?.role) {
      await this.getRoleLookupData(this.currentApplication, currentUserApplicationRole?.role);
    }
    this.applicationRoleForUser[app.id] = currentUserApplicationRole?.role;
    this.applicationRoleContextForUser[app.id] = currentUserApplicationRole?.roleContext;
    const roleContext = this.applicationRolesLookupData[app.id]?.[this.applicationRoleForUser[app.id]];
    const selectedRoleContext = roleContext?.items?.find((i) => i.id === currentUserApplicationRole?.roleContext);
    this.selectedAppRoleContext[app.id] = {name: selectedRoleContext?.name, id: selectedRoleContext?.id};
    const attrs = this.getApplicationAttributes(app);
    for (const attr of attrs) {
      if (attr.default === 'true') {
        attr.default = true;
      }
      this.roleAttributes(attr);
    }
  }

  private async getRoleLookupData(app: Application, role: string): Promise<{ items: { id: string | number, name: string }[], label: string, desc?: string } | undefined> {
    this.roleLookupDataLoading = true;
    try {
      const config = this.getApplicationRoles(app).find((a: any) => a.id === role);
      if (!config?.lookup) {
        return;
      }    
      let roleLookupResult = this.applicationRolesLookupData[app.id]?.[role];
      if (!roleLookupResult) {
        if (!this.applicationRolesLookupData[app.id]) {
          this.applicationRolesLookupData[app.id] = {};
        }
        roleLookupResult = this.applicationRolesLookupData[app.id][role] = {
          items: [],
          label: 'Unknown',
          desc: undefined
        };
        roleLookupResult.label = config.lookup.name;
        if (config.lookup.description) {
          roleLookupResult.desc = config.lookup.description;
        }
        const lookupData = await ApplicationService.makeExternalApplicationAPICall(this.tenantStore.tenantId!, app.id, config.lookup.url);
        if (lookupData?.length) {
          roleLookupResult.items = lookupData.map((d: any) => ({
              id: d[config.lookup.identityField],
              name: d[config.lookup.displayField]
          }));
        }
      }
      return roleLookupResult; 
    } finally {
      this.roleLookupDataLoading = false;
    }
  }

  private getApplicationRoles(app: Application) {
    return app?.details?.users?.roles || [];
  }

  private roleAttributes(attribute: any) {
    if (this.currentApplication || this.newApp) {
      const roleForCurrentApp = this.currentUser?.application_roles?.find((r) => (r.application_id === this.currentApplication?.id) || (r.application_id === this.newApp?.id));

      let value;
      if (roleForCurrentApp?.attributes?.length) {
        if (attribute) {
          const currentAttribute = roleForCurrentApp?.attributes.find((a: any) => a.slug === attribute.id);
          if (currentAttribute) {
            value = typeof currentAttribute.value === 'string' ? currentAttribute.value === 'true' : currentAttribute.value;
          } else {
            value = attribute.default;
          }
        }
      } else {
        value = attribute.default;
      }
      this.attributes[attribute.id] = value;

      this.currentApplication ? this.applicationRoleForUser[this.currentApplication.id] = roleForCurrentApp?.role :
        this.newApp ? this.applicationRoleForUser[this.newApp.id] = roleForCurrentApp?.role : null;
    }
  }

  private getApplicationAttributes(app?: Application | null) {
    return app?.details?.users?.attributes.map((a: { id: string; name: string; description: string; default: string }) => {
      return {
        ...a,
        default: a.default
      }
    }) || [];
  }

  private async fetchUsers(take = 100, skip = 0, sort = 'users.first_name', desc = false, filters?: any, searchString?: string) {
    if (this.tenantStore.tenantId) {
      this.users = await UserService.searchUsers(this.tenantStore.tenantId, take, skip, sort, desc, filters, searchString);
    }
  }

  private async updateTableOptions(opts: any) {
    let sort = opts[0]?.key || 'full_name';
    if (sort === 'full_name') {
      sort = 'first_name';
    }
    await this.fetchUsers(100, 0, `users.${sort}`, opts[0]?.order === 'desc', undefined)
  }

  @Watch('searchNames')
  private async search(string: string) {
    this.searchDebounced(string)
  }

  private searchDebounced(searchString: string) {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(async () => {
      await this.fetchUsers(100, 0, '', false, undefined, searchString);
    }, 500)
  }

  private async addUser() {
    if (this.tenantStore.tenantId) {
      this.loading = true;
      try {
        await UserService.addUser(this.tenantStore.tenantId, {user: this.user, roles: [this.user.role!]})
      } finally {
        this.user = {
          first_name: '',
          last_name: '',
          email: '',
          role: undefined,
          applications: []
        };
        await this.fetchUsers()
        this.loading = false;
      }
    }
  }


  private async removeUser() {
    this.loading = true
    if (this.selectedUsers.length && this.tenantStore.tenantId) {
      for (const user of this.selectedUsers) {
        if (user.id) {
          await UserService.removeUser(this.tenantStore.tenantId, user.id);
        }
      }
      this.users = await UserService.searchUsers(this.tenantStore.tenantId);
      this.selectedUsers = [];
    }
    this.loading = false;
  }


  private addApplicationRow(user: User | null) {
    if (user?.id) {
      this.newApps.push({ name: '', type: '' });
    }
  }

  private async addApplicationAccess() {
    if (this.newApp && this.currentUser) {
      this.applicationsToAdd.push(this.newApp);
      this.opened.push(this.applicationsToAdd.findIndex((a) => a.id === this.newApp?.id))
      this.currentUser.application_roles?.push({ attributes: null, application_id: this.newApp.id, role: null, roleContext: null });

      this.attributes = this.newApp.details?.users?.attributes?.reduce((acc: any, item: any) => {
        acc[item.id] = {
          slug: item.id,
          value: item.default === "true"
        };
        return acc;
      }, {}) || {};

      await this.mapConfig(this.newApp);
      this.newApp = null;
      this.newApps = [];
    }
  }

  private removeNewApplication(app: Application) {
    this.applicationsToAdd = this.applicationsToAdd.filter((a) => a.id !== app.id);
    //this.selectedAppRole[app.id] = {};
    //this.applicationRoleForUser[app.id] = null;
    //this.selectedAppRoleContext[app.id] = {};
    //this.applicationRoleContextForUser[app.id] = null;
  }

  private async removeApplication(app: Application) {
    this.loading = true;
    try {
      this.applicationsToRemove.push(app);
      if (this.currentUser) {
        this.currentUser.applications = this.currentUser?.applications?.filter((a) => a.id !== app.id);
        //this.selectedAppRole[app.id] = {};
        //this.applicationRoleForUser[app.id] = null;
        //this.selectedAppRoleContext[app.id] = {};
        //this.applicationRoleContextForUser[app.id] = null;
        this.appConfigDirtied = true;
      }
    } finally {
      this.appConfigDirtied = true;
      this.loading = false;
    }
  }

  private applicationRole(roles: any, appId: number): { name: string } {
    if (!roles.length) {
      return { name: 'Unknown' }
    }
    const role = roles.find((r: { application_id: number }) => r.application_id === appId)?.role;
    if (!role) {
      return { name: 'Unknown' }
    }

    return { name: `${role.charAt(0).toUpperCase()}${role.slice(1)}` } || { name: 'Unknown' };
  }

  private initials(first: string, last: string): string {
    return `${first.charAt(0)} ${last.charAt(0)}`
  }

  private fullName(first: string, last: string): string {
    return `${first} ${last}`
  }

  private async saveUser() {
    this.loading = true;
      try {
        if (this.tenantStore.tenantId && (this.currentUser && this.currentUser.id)) {
          if (this.currentUser && this.currentUser.applications) {
            const apps = [...this.currentUser.applications.filter((a) => a), ...this.applicationsToAdd];
            const applicationIds = apps.map((a) => a.id).filter((a) => !this.applicationsToRemove.map((r) => r.id).includes(a));
            const ids = Array.from(new Set([...applicationIds]));
            let applications: any[] = [];
            ids.forEach((id) => {
              this.currentApplication = apps.find((a) => a.id === id);
              applications.push({
                application_id: id,
                application_type: this.currentApplication?.type,
                ...( this.currentApplication?.type === 'hybrid' && { ...{ applications: this.applicationsForHybrid.filter((a: any) => a.enabled).map((a) => a.id)}}),
                role: this.applicationRoleForUser[id],
                roleContext: this.applicationRoleContextForUser[id] || null,
                attributes: this.getApplicationAttributes(this.currentApplication).map((a: Application) => {
                  return {
                    slug: a.id,
                    value: this.attributes[a.id]
                  }
                })
              })
            });
            if (this.selectedRole.id && !this.currentUser.roles?.map((r) => r.id).includes(this.selectedRole.id)) {
              await UserService.updateUser(this.tenantStore.tenantId, this.currentUser.id, { applications: applications || [], role: this.selectedRole.id });
            }

            await TenantService.updateUserRoleAndApplicationAccess(this.tenantStore.tenantId, Number(this.currentUser?.id), applications);


            const subApps = [];
            for (const app of this.applicationsForHybrid) {
              if ((app as any).enabled) {
                subApps.push(app.id);
              }
            }
            for (const app of apps) {
              await UserService.updateUserRolesForHybridApplications(this.tenantStore.tenantId, Number(this.currentUser?.id), subApps, app.id);
            }
          }
        }
      } catch (e) {
        const err = e as Error;
        console.error(err)
      } finally {
        await this.fetchUsers();
        this.applicationsToAdd = [];
        this.applicationsToRemove = [];
        this.loading = false;
        this.editDialog = false;
        this.tab = 'details'
      }
  }

  private resetDialog() {
    this.appConfigDirtied = false;
    this.user = {
      first_name: '',
      last_name: '',
      email: '',
      role: undefined,
      applications: []
    };
  }

  private async editUser(item: any) {
    this.loading = true;
    try {
      this.currentUser = {...item};
      const jobs = this.currentUser?.applications?.length ? this.currentUser.applications.map((a) => this.mapConfig(a)) : [];
      await Promise.all(jobs);
      this.editDialog = true;
      this.selectedRole = {...this.currentUser?.roles?.[0]};
    } finally {
      this.loading = false;
    }
  }

  private hasRoleAssigned() {
    for (const app of [...(this.applicationsToAdd || []), ...(this.currentUser?.applications || [])]) {
      const roles = this.getApplicationRoles(app);
      if (roles.length) {
        const appRole = this.selectedAppRole[app.id];
        if (!appRole?.id) {
          return false;
        } else if (roles.find((r: any) => appRole.id === r.id)?.lookup && !this.selectedAppRoleContext[app.id]?.id) {
          return false;
        }
      }
    }
    return true;
  }

  private roleProps(item: any) {
    return {
      title: item.name,
      subtitle: item.description
    }
  }

  private roleContextProps(item: any) {
    return {
      title: item.name,
      subtitle: item.description
    }
  }

  private getHybridAppsForUser(user: User, apps: any[]) {
    const attrs = user?.application_roles?.filter((ar: { attributes: { hybrid_application_id: number }}) => !!ar.attributes?.hybrid_application_id);
    return apps.filter((a) => attrs?.map((h) => h.application_id).includes(a.applicationId))
  }
}
</script>

<template>

  <div>
    <v-row class="pt-2 mx-4" justify="space-between" align="center" dense="">
      <v-col cols="12" md="6">
        <v-row align="center" dense="">
          <v-col cols="12">
            <h2>User management</h2>
          </v-col>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="4" md="auto">
                <v-dialog width="500" @update:modelValue="resetDialog()">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" width="160" variant="tonal" color="primary">
                      <v-icon icon="mdi-account-plus" start></v-icon>
                      {{ mdAndUp ? 'Add user' : 'Add' }}
                    </v-btn>
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-card title="Add a user">
                      <v-card-text>
                        <v-form>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field hide-details density="comfortable" label="First name" v-model="user.first_name" @update:model-value="appConfigDirtied = true"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field hide-details density="comfortable" label="Last name" v-model="user.last_name" @update:model-value="appConfigDirtied = true"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field hide-details density="comfortable" label="Email" v-model="user.email" @update:model-value="appConfigDirtied = true" type="email"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-select hide-details density="comfortable" label="Role" :items="roles" item-title="name" item-value="id" v-model="user.role" @update:model-value="appConfigDirtied = true"></v-select>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            :loading="loading"
                            :disabled="!user.first_name || !user.last_name || !user.email || !user.role || loading"
                            color="primary"
                            @click="addUser(); isActive.value = false;"
                        >
                          Add user
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
              <v-col cols="4" md="auto">
                <v-btn size="small" width="160" variant="tonal" :disabled="!selectedUsers.length" color="primary" @click="removeUser">
                  <v-icon icon="mdi-account-remove" start></v-icon>
                  {{ mdAndUp ? 'Remove user' : 'Remove' }}
                </v-btn>
              </v-col>
<!--              <v-col cols="4" md="auto">-->
<!--                <v-btn size="small" width="160" variant="tonal" color="primary">-->
<!--                  <v-icon icon="mdi-upload" start></v-icon>-->
<!--                  {{ mdAndUp ? 'Bulk add users' : 'Bulk add' }}-->
<!--                </v-btn>-->
<!--              </v-col>-->

            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12">
            <v-text-field hide-details density="compact" placeholder="Search users" append-inner-icon="mdi-magnify" v-model="searchNames"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
        :items="users"
        :headers="headers"
        v-model="selectedUsers"
        fixed-header
        show-select
        return-object
        height="calc(100vh - 180px)"
        @update:sort-by="updateTableOptions"
        items-per-page="25"
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <v-avatar color="secondary">{{ initials(item.first_name, item.last_name) }}</v-avatar>
      </template>
      <template v-slot:[`item.full_name`]="{ item }">
        {{ fullName(item.first_name, item.last_name) }}
      </template>
      <template v-slot:[`item.role`]="{ item }">
        <!--TODO: Actually fetch role for this user in tenant-->
        {{ item.roles.map((r) => r.id).includes(1) ? 'Manager' : item.roles.map((r) => r.id).includes(2) ? 'User' : 'Unknown' }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.status === 'active'" class="d-flex">
          <v-icon start icon="mdi-circle" color="success"></v-icon>
          Active
        </div>
        <div v-if="item.status === 'pending'" class="d-flex">
          <v-icon start icon="mdi-circle" color="grey"></v-icon>
          Pending
        </div>
      </template>
      <template v-slot:[`item.applications`]="{ item }">
        <div class="d-flex">
          <v-tooltip v-for="app of item.applications" :key="app.id" location="bottom" open-delay="200">
            <template v-slot:activator="{ props }">
              <v-avatar v-bind="props" size="32" color="secondary" rounded class="mx-1">{{ app.name.charAt(0) }}</v-avatar>
            </template>
            <template v-slot:default>
              <div v-if="app.type !=='hybrid'">{{ app.name }}</div>
              <div v-else-if="app.type === 'hybrid' && getHybridAppsForUser(item, app.details.applications).length">
                <v-row no-gutters justify="center">
                  <v-col cols="auto">
                    {{ app.name }}
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>Applications in this hybrid app:</v-col>
                </v-row>
                <v-row no-gutters v-for="subApp of getHybridAppsForUser(item, app.details.applications)" :key="subApp.applicationId">
                  <v-col>
                    <v-icon>mdi-circle-small</v-icon>
                    {{ subApp.name }}
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                This user has no application access within the hybrid application
              </div>
            </template>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
          <v-btn size="small" variant="tonal" color="primary" width="80" @click="editUser(item)" :loading="loading">Edit</v-btn>
      </template>
    </v-data-table>
    <v-dialog width="500" v-model="editDialog" id="edit-dialog" @update:modelValue="applicationsToAdd = []; newApps = []; tab = 'details'; appConfigDirtied = false;">
      <template v-slot:default>
        <v-card title="Edit user">
          <v-tabs
              v-model="tab"
          >
            <v-tab value="details">Details</v-tab>
            <v-tab value="config">Configuration</v-tab>
          </v-tabs>
          <v-card-text>
            <v-window v-model="tab">
              <v-window-item value="details">
                <v-form>
                  <v-row align="center">
                    <v-col cols="12" class="pb-0">
                      <v-text-field label="Name" hide-details density="comfortable" readonly :model-value="fullName(currentUser.first_name, currentUser.last_name)"></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-text-field label="Email" hide-details density="comfortable" readonly :model-value="currentUser.email" type="email"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select @update:model-value="appConfigDirtied = true; selectedRole = $event" hide-details density="comfortable" label="Role" :items="roles" item-title="name" item-value="id" return-object :model-value="selectedRole"></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-window-item>
              <v-window-item value="config">
                <v-row no-gutters justify="space-between">
                  <v-col cols="auto">
                    <p>
                      Application access
                    </p>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn size="small" color="primary" variant="plain" @click="addApplicationRow(currentUser); appConfigDirtied = true;"><v-icon start icon="mdi-plus-circle"></v-icon> Add </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-list v-for="app of currentUser.applications" :key="app.name">
                      <template v-if="currentUser && currentUser.applications?.length && currentUser.applications.every((app) => app.id)">
                        <v-list-item rounded class="pa-0">
                          <v-expansion-panels @update:model-value="mapConfig(app);">
                            <v-expansion-panel bg-color="rgba(0,0,0,0.05)">
                              <v-expansion-panel-title class="py-0 px-2" static hide-actions>
                                <template v-slot:default="{ collapseIcon, expandIcon, expanded }">
                                  <v-row no-gutters justify="space-between" align="center">
                                    <v-col cols="1">
                                      <span v-if="expanded"><v-icon>{{ collapseIcon }}</v-icon></span>
                                      <span v-else><v-icon>{{ expandIcon }}</v-icon></span>
                                    </v-col>
                                    <v-col cols="10">
                                      <v-list-item-title>{{ app.name }}</v-list-item-title>
                                      <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                                    </v-col>
                                    <v-col cols="1">
                                      <v-list-item-action>
                                        <v-btn @click.stop="showRemoveDialog[app.id] = true" icon variant="plain" color="primary" size="small"><v-icon icon="mdi-delete" size="large"></v-icon></v-btn>
                                      </v-list-item-action>
                                    </v-col>
                                  </v-row>
                                </template>
                              </v-expansion-panel-title>
                              <v-expansion-panel-text class="mt-1">
                                <v-row>
                                  <v-col cols="12" v-if="getApplicationRoles(app).length && app.type !== 'hybrid'">
                                    <p class="text-disabled mb-4">Assign a role to this user for this application</p>
                                    <v-row>
                                      <v-select
                                          class="mx-2"
                                          label="Application role"
                                          :items="getApplicationRoles(app)"
                                          v-model="selectedAppRole[app.id]"
                                          density="comfortable"
                                          @update:model-value="appConfigDirtied = true; applicationRoleForUser[app.id] = selectedAppRole[app.id].id; applicationRoleContextForUser[app.id] = null; selectedAppRoleContext[app.id] = {}; getRoleLookupData(app, applicationRoleForUser[app.id]);"
                                          hide-details
                                          :item-props="roleProps"
                                      >
                                        <template #selection="{ item }">
                                          <span class="text-capitalize">{{ item.value.name }}</span>
                                        </template>
                                      </v-select>
                                    </v-row>
                                    <v-row v-if="applicationRolesLookupData[app.id]?.[applicationRoleForUser[app.id]]">
                                      <v-select
                                          class="mx-2"
                                          :label="applicationRolesLookupData[app.id][applicationRoleForUser[app.id]].label"
                                          :items="applicationRolesLookupData[app.id][applicationRoleForUser[app.id]].items"
                                          v-model="selectedAppRoleContext[app.id]"
                                          density="comfortable"
                                          @update:model-value="appConfigDirtied = true; applicationRoleContextForUser[app.id] = selectedAppRoleContext[app.id].id;"
                                          hide-details
                                          :item-props="roleContextProps"
                                          :loading="roleLookupDataLoading"
                                      >
                                        <template #selection="{ item }">
                                          <span class="text-capitalize">{{ item.value.name }}</span>
                                        </template>
                                      </v-select>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" v-else-if="app.type === 'hybrid'">
                                    <v-row>
                                      <v-col cols="12">
                                        <p class="text-disabled">In a hybrid application, individual applications can be toggled on or off. By default, all applications are toggled on.</p>
                                      </v-col>
                                      <v-col cols="12" v-for="app of applicationsForHybrid" :key="app.id">
                                        <v-row no-gutters justify="space-between" align="center">
                                          <v-col>
                                            <v-list-item-title>{{ currentApplication?.details?.applications?.find((a) => a.applicationId === app.id)?.tabName || app.name }}</v-list-item-title>
                                            <v-list-item-subtitle>Enabled for this user</v-list-item-subtitle>
                                          </v-col>
                                          <v-col cols="2">
                                            <v-switch hide-details v-if="app" density="compact" v-model="app.enabled" @update:model-value="appConfigDirtied = true" color="primary"></v-switch>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                  <v-col cols="12" v-else>
                                    <p class="text-disabled">This application does not have any roles</p>
                                  </v-col>
                                  <v-col cols="12" v-if="getApplicationAttributes(app).length">
                                    <p class="text-disabled mb-4">Configure application attributes for this user</p>
                                    <v-row class="ml-2">
                                      <v-col cols="12" v-for="attribute of getApplicationAttributes(app)" :key="attribute.id">
                                        <v-row no-gutters justify="space-between">
                                          <v-col>
                                            <v-list-item-title>{{ attribute.name }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ attribute.description }}</v-list-item-subtitle>
                                          </v-col>
                                          <v-col cols="2">
                                            <v-switch hide-details v-if="attribute.type === 'boolean'" density="compact" v-model="attributes[attribute.id]" @update:model-value="appConfigDirtied = true" color="primary"></v-switch>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-text>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-list-item>
                      </template>
                      <v-dialog :attach="true" v-model="showRemoveDialog[app.id]">
                        <template v-slot:default="{ isActive }">
                          <v-card max-width="460">
                            <v-card-title>Remove application from user</v-card-title>
                            <v-card-text>
                              Are you sure you want to remove this application from this user? The user will no longer have access to the application.
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                @click="isActive.value = false">
                                Cancel
                              </v-btn>
                              <v-btn
                                :loading="loading"
                                :disabled="loading"
                                color="primary"
                                @click="removeApplication(app); isActive.value = false">
                                Remove
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-list>
                    <v-list>
                      <v-list-item v-for="app of applicationsToAdd" :key="app.name" style="background-color: rgba(0,0,0,0.05)" rounded class="pa-0 mb-4">
                        <v-expansion-panels :model-value="opened">
                          <v-expansion-panel bg-color="rgba(0,0,0,0.05)">
                            <v-expansion-panel-title class="py-0 px-2" static hide-actions>
                              <template v-slot:default="{ collapseIcon, expandIcon, expanded }">
                                <v-row no-gutters justify="space-between" align="center">
                                  <v-col cols="1">
                                    <span v-if="expanded"><v-icon>{{ collapseIcon }}</v-icon></span>
                                    <span v-else><v-icon>{{ expandIcon }}</v-icon></span>
                                  </v-col>
                                  <v-col cols="10">
                                    <v-list-item-title>{{ app.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                                  </v-col>

                                  <v-col cols="1">
                                    <v-list-item-action>
                                      <v-btn icon variant="plain" color="primary" size="small" @click="removeNewApplication(app)"><v-icon icon="mdi-delete" size="large"></v-icon></v-btn>
                                    </v-list-item-action>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-expansion-panel-title>
                            <v-expansion-panel-text class="mt-1">
                              <v-row>
                                <v-col cols="12" v-if="getApplicationRoles(app).length">
                                  <p class="text-disabled mb-4">Assign a role to this user for this application</p>
                                  <v-row>  
                                    <v-select
                                      class="mx-2"
                                      label="Application role"
                                      :items="getApplicationRoles(app)"
                                      v-model="selectedAppRole[app.id]"
                                      density="comfortable"
                                      @update:model-value="appConfigDirtied = true; applicationRoleForUser[app.id] = selectedAppRole[app.id].id; applicationRoleContextForUser[app.id] = null; selectedAppRoleContext[app.id] = {}; getRoleLookupData(app, applicationRoleForUser[app.id]);"
                                      hide-details
                                      :item-props="roleProps"
                                    >
                                      <template #selection="{ item }">
                                        <span class="text-capitalize">{{ item.value.name }}</span>
                                      </template>
                                    </v-select>
                                  </v-row>
                                  <v-row v-if="applicationRolesLookupData[app.id]?.[applicationRoleForUser[app.id]]">
                                      <v-select
                                          class="mx-2"
                                          :label="applicationRolesLookupData[app.id][applicationRoleForUser[app.id]].label"
                                          :items="applicationRolesLookupData[app.id][applicationRoleForUser[app.id]].items"
                                          v-model="selectedAppRoleContext[app.id]"
                                          density="comfortable"
                                          @update:model-value="appConfigDirtied = true; applicationRoleContextForUser[app.id] = selectedAppRoleContext[app.id].id;"
                                          hide-details
                                          :item-props="roleContextProps"
                                          :loading="roleLookupDataLoading"
                                      >
                                        <template #selection="{ item }">
                                          <span class="text-capitalize">{{ item.value.name }}</span>
                                        </template>
                                      </v-select>
                                    </v-row>

                                </v-col>

                                <v-col cols="12" v-else-if="app.type === 'hybrid'">
                                  <v-row>
                                    <v-col cols="12">
                                      <p class="text-disabled">In a hybrid application, individual applications can be toggled on or off. By default, all applications are toggled on.</p>
                                    </v-col>
                                    <v-col cols="12" v-for="ha of applicationsForHybrid" :key="ha.id">
                                      <v-row no-gutters justify="space-between" align="center">
                                        <v-col>
                                          <v-list-item-title>{{ app?.details?.applications?.find((a) => a.applicationId === ha.id)?.tabName || ha.name }}</v-list-item-title>
                                          <v-list-item-subtitle>Enabled for this user</v-list-item-subtitle>
                                        </v-col>
                                        <v-col cols="2">
                                          <v-switch hide-details v-if="ha" density="compact" v-model="ha.enabled" @update:model-value="appConfigDirtied = true" color="primary"></v-switch>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" v-else>
                                  <v-list-item-title>This application does not have any roles</v-list-item-title>
                                </v-col>
                                <v-col cols="12" v-if="getApplicationAttributes(app).length">
                                  <p class="text-disabled mb-4">Configure application attributes for this user</p>
                                  <v-row class="ml-2">
                                    <v-col cols="12" v-for="attribute of getApplicationAttributes(app)" :key="attribute.id">
                                      <v-row no-gutters justify="space-between">
                                        <v-col>
                                          <v-list-item-title>{{ attribute.name }}</v-list-item-title>
                                          <v-list-item-subtitle>{{ attribute.description }}</v-list-item-subtitle>
                                        </v-col>
                                        <v-col cols="2">
                                          <v-switch hide-details v-if="attribute.type === 'boolean'" density="compact" v-model="attributes[attribute.id]" @update:model-value="appConfigDirtied = true" color="primary"></v-switch>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-expansion-panel-text>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-list-item>
                      <v-list-item
                          class="mb-1 px-0 pt-0"
                          v-for="app of newApps"
                          :key="app.name"
                      >
                        <v-combobox
                            class="app-combobox"
                            clearable
                            :items="filteredTenantApplications"
                            item-title="name"
                            hide-details
                            density="comfortable"
                            :disabled="!filteredTenantApplications.length"
                            :placeholder="`${filteredTenantApplications.length ? 'Search applications to assign to this user' : 'There are no more applications to assign to this user'}`"
                            return-object
                            :menu-props="{ maxWidth: 452 }"
                        >
                          <template v-slot:selection="{ item }">
                            <div class="d-flex flex-column">
                              <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                              <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                            </div>
                          </template>
                          <template v-slot:item="{ item }">
                            <div class="d-flex flex-column">
                              <v-list-item @click="async () => { newApp = item.raw; await addApplicationAccess() }">
                                <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                              </v-list-item>
                            </div>
                          </template>
                        </v-combobox>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                :loading="loading"
                :disabled="loading || !appConfigDirtied || !hasRoleAssigned()"
                color="primary"
                @click="saveUser(); appConfigDirtied = false;"
            >
              Save user
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<style scoped>
</style>

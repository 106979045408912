import { appAxios } from '../app.axios';
import { Role, User } from '@/types';
import Transformation from '@/classes/transformation';

export class UserService {
  public async searchUsers( tenantId: number,
                            take = 0, skip = 0, sort = '',
                            desc = false, filters: Record<string, Record<string, boolean>> = {},
                            searchNames: string | null = null, applicationId?: number ): Promise<User[]> {
    let filtersString = '';

    const filterQuery = Transformation.filterMapToQueryString( filters );

    if ( filterQuery ) {
      filtersString += `&${filterQuery}`;
    }
    if (applicationId) {
      filtersString += `&applicationId=${applicationId}`
    }
    if ( searchNames ) {
      filtersString += `&searchNames=${encodeURIComponent( searchNames )}`;
    }
    const url = `/tenants/${tenantId}/users?take=${take}&skip=${skip}&sort=${sort}&desc=${desc}${filtersString}`;
    return ( await appAxios.get( url ) ).data;
  }

  public async searchSuperAdminUsers(
    take = 0, skip = 0, sort = '',
    desc = false ): Promise<User[]> {

    const url = `/users?take=${take}&skip=${skip}&sort=${sort}&desc=${desc}`;
    return ( await appAxios.get( url ) ).data;
  }

  public async getRoles(): Promise<Role[]> {
    const url = `/roles`;
    return ( await appAxios.get( url ) ).data;
  }

  // public async updateUser(projectId: number, userId: number, payload: { user: { first_name: string; last_name: string; alerts_enabled: boolean}, roles?: number[] | null; sites?: Array<{ site_id: number; roles: number[] }> }) {
  //   const url = `/projects/${tenantId}/users/${userId}`;
  //   return (await appAxios.put(url, payload)).data;
  // }
  public async addUser( tenantId: number, payload: { user: { first_name: string; last_name: string; email: string }, roles?: number[] | null } ) {
    const url = `/tenants/${tenantId}/users`;
    return ( await appAxios.post( url, payload ) ).data;
  }

  public async updateUser(tenantId: number, userId: number, payload: { role?: number; applications: any[] } ) {
    const url = `/tenants/${tenantId}/users/${userId}`;
    return ( await appAxios.put( url, payload ) ).data;
  }

  public async addSuperUser( payload: { user: { first_name: string; last_name: string; email: string }}) {
    const url = `/users`;
    return ( await appAxios.post( url, payload ) ).data;
  }

  public async removeUser(tenantId: number, userId: number) {
    const url = `/tenants/${tenantId}/users/${userId}`;
    return (await appAxios.delete(url)).data;
  }
  public async removeSuperUser(tenantId: number, userId: number) {
    const url = `/users/${userId}`;
    return (await appAxios.delete(url)).data;
  }

  public async getCurrentUser(): Promise<User> {
    const url = '/users/me';
    return (await appAxios.get(url)).data;
  }

  public async updateUserRolesForHybridApplications(tenantId: number, userId: number, applicationIds: number[], hybridApplicationId: number) {
    const url = `/tenants/${tenantId}/users/${userId}/hybrid`;
    return (await appAxios.put(url, { applicationIds, hybridApplicationId })).data;
  }
}

export default new UserService();

import {createApp} from 'vue'
import App from "@/App.vue";
// Vuetify
import { createPinia } from "pinia";
import "@mdi/font/css/materialdesignicons.css";
import router from "./router";
import {loadTheme, vuetify} from "@/plugins/vuetify";
import {createVuetify} from "vuetify";
import {useTenantStore} from "@/stores/tenantStore";

const pinia = createPinia();
const app = createApp(App);

async function renderApp() {
  let vuetifyOverride = vuetify;
  try {
    vuetifyOverride = await loadTheme();
  } catch (e) {
    console.error(e);
  }

  app.use(router).use(pinia).use(createVuetify(vuetifyOverride)).mount('#app');

  useTenantStore().setDefaultTheme({
    primary: vuetifyOverride.theme.themes.currentTheme.colors?.primary || vuetify.theme.themes.currentTheme.colors.primary,
    secondary: vuetifyOverride.theme.themes.currentTheme.colors?.secondary || vuetify.theme.themes.currentTheme.colors.secondary,
    logo: process.env.VUE_APP_PUBLIC_CONTENT_URL + '/themes/default.png'
  })
}

renderApp();
<script lang="ts">
import {Vue, Component, Watch} from 'vue-facing-decorator';
import UserService from "@/services/user.service";
import {useUserStore} from "@/stores/userStore";
import {Application, User} from "@/types";
import ActionButton from "@/components/ActionButton.vue";
import {useDisplay} from "vuetify";
import AdminService from "@/services/admin.service";
import {useNotificationStore} from "@/stores/notificationStore";
@Component({ components: { ActionButton }})
export default class SuperUserManagement extends Vue {
  private notificationStore = useNotificationStore();
  private mdAndUp = useDisplay().mdAndUp;
  private userStore = useUserStore();
  private loading = false;
  private users: User[] = [];
  private selectedUsers: User[] = [];
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: '', value: 'avatar', sortable: false, width: '60px' },
    { title: 'Name', value: 'full_name', sortable: true },
    { title: 'Email', value: 'email', sortable: true },
    { title: 'Status', value: 'status', sortable: true }
  ];
  private searchTimeout: any = {};
  private searchNames = '';
  private user: { first_name: string; last_name: string; email: string; } = {
    first_name: '',
    last_name: '',
    email: '',
  }
  private async mounted() {
    await this.fetchSuperAdmins();
  }

  private async fetchSuperAdmins() {
    this.users = await AdminService.searchSuperUsers();
  }

  private async updateTableOptions(opts: any) {
    let sort = opts[0]?.key || 'full_name';
    if (sort === 'full_name') {
      sort = 'first_name';
    }
    this.users = await AdminService.searchSuperUsers(100, 0, `users.${sort}`, opts[0]?.order === 'desc', undefined)
  }

  @Watch('searchNames')
  private async search(string: string) {
    this.searchDebounced(string)
  }

  private searchDebounced(searchString: string) {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(async () => {
      this.users = await AdminService.searchSuperUsers(100, 0, '', false, undefined, searchString)
    }, 500)
  }
  private async addSuperUser() {
    this.loading = true;
    try {
      await AdminService.addSuperUser(this.user);
    } catch (e) {
      const err = e as Error;
      console.error(err)
    } finally {
      this.loading = false;
      await this.fetchSuperAdmins();
    }
  }

  private async removeSuperUser() {
    if (this.selectedUsers.length) {
      for (const user of this.selectedUsers) {
        if (user.id) {
          await AdminService.removeSuperUser(user.id);
        }
      }
      this.selectedUsers = [];
      await this.fetchSuperAdmins();
    }
  }

  private initials(first: string, last: string): string {
    return `${first.charAt(0)} ${last.charAt(0)}`
  }

  private fullName(first: string, last: string): string {
    return `${first} ${last}`
  }

  private resetDialog() {
    this.user = {
      first_name: '',
      last_name: '',
      email: ''
    };
  }
}
</script>

<template>
  <div>
    <v-row class="pt-2 mx-4" justify="space-between" align="center" dense="">
      <v-col cols="12" md="6">
        <v-row align="center" dense="">
          <v-col cols="12">
            <h2>Super user management</h2>
          </v-col>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="4" md="auto">
                <v-dialog width="500" @update:modelValue="resetDialog()">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" width="200" variant="tonal" color="primary">
                      <v-icon icon="mdi-account-plus" start></v-icon>
                      {{ mdAndUp ? 'Add super user' : 'Add' }}
                    </v-btn>
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-card title="Add a super user">
                      <v-card-text>
                        <v-form>
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field hide-details density="compact" label="First name" v-model="user.first_name"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field hide-details density="compact" label="Last name" v-model="user.last_name"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field hide-details density="compact" label="Email" v-model="user.email" type="email"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            :loading="loading"
                            :disabled="!user.first_name || !user.last_name || !user.email || loading"
                            color="primary"
                            @click="addSuperUser(); isActive.value = false"
                        >
                          Add super user
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
              <v-col cols="4" md="auto">
                <v-btn size="small" width="200" variant="tonal" :disabled="!selectedUsers.length" color="primary" @click="removeSuperUser()">
                  <v-icon icon="mdi-account-minus" start></v-icon>
                  {{ mdAndUp ? 'Remove super user' : 'Remove' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12">
            <v-text-field hide-details density="compact" placeholder="Search super users" append-inner-icon="mdi-magnify" v-model="searchNames"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
        :items="users"
        :headers="headers"
        show-select
        return-object
        v-model="selectedUsers"
        height="calc(100vh - 180px)"
        @update:sort-by="updateTableOptions"
        items-per-page="25"
    >
      <template v-slot:[`item.avatar`]="{ item }">
        <v-avatar color="secondary">{{ initials(item.first_name, item.last_name) }}</v-avatar>
      </template>
      <template v-slot:[`item.full_name`]="{ item }">
        {{ fullName(item.first_name, item.last_name) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <div v-if="item.status === 'active'" class="d-flex">
          <v-icon start icon="mdi-circle" color="success"></v-icon>
          Active
        </div>
        <div v-if="item.status === 'pending'" class="d-flex">
          <v-icon start icon="mdi-circle" color="grey"></v-icon>
          Pending
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>

</style>

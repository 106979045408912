<script lang="ts">
import { Vue, Component, Prop, Watch, Emit } from 'vue-facing-decorator';

@Component({})
export default class Snackbar extends Vue {
  @Prop() private body!: string;
  @Prop() private icon!: string;
  @Prop() private open!: boolean;
  private snackbarActive = false;

  @Watch('open', {immediate: true})
  private onOpen() {
    this.snackbarActive = this.open;
  }

  @Emit()
  private updateShowSnackbar(show: boolean) {
    return show;
  }

}
</script>

<template>
  <v-snackbar timeout="1500" v-model="snackbarActive" @update:model-value="updateShowSnackbar">
    <v-icon start>{{icon}}</v-icon>
    {{ body }}
  </v-snackbar>
</template>

<style scoped>

</style>
<script lang="ts">
import {Vue, Component, Watch} from 'vue-facing-decorator';
import {Application, Tenant} from "@/types";
import {useDisplay} from "vuetify";
import TenantService from "@/services/tenant.service";
import ApplicationService from "@/services/application.service";
import {useTenantStore} from "@/stores/tenantStore";
import {useNotificationStore} from "@/stores/notificationStore";

@Component({})
export default class Tenants extends Vue {
  private notificationStore = useNotificationStore();
  private tenantStore = useTenantStore();
  private mdAndUp = useDisplay().mdAndUp;
  private searchTimeout: any = {};
  private logo: File[] = [];
  private savedLogo = '';
  private searchNames = '';
  private loading = false;
  private primaryDialog = false;
  private secondaryDialog = false;
  private editDialog = false;
  private tenants: Tenant[] = [];
  private currentTenant: Tenant | null = null;
  private selectedTenants: Tenant[] = [];
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: 'Name', value: 'name', sortable: true, width: '20%' },
    { title: 'Status', value: 'state', sortable: true, width: '20%' },
    { title: 'Applications', value: 'applications', sortable: false, width: '30%' },
    { title: '', value: 'actions', sortable: false },
  ];
  private tenant = {
    name: '',
    state: 'active'
  }
  private newApp: Application | null = null;
  private newApps: Partial<Application>[] = [];
  private applicationsToRegister: Application[] = [];
  private applications: Application[] = [];
  private rules = [ (value: any) => { return !value || !value.length || value[0].size < 1000000 || 'Logo size should be less than 1 MB!' }];
  private invalidLogo = false;
  private primary = '';
  private secondary = '';

  @Watch('logo')
  private onLogoChange() {
    this.invalidLogo = this.logo[0]?.size > 1000000;
  }

  @Watch('searchNames')
  private async search(string: string) {
    this.searchDebounced(string)
  }

  private searchDebounced(searchString: string) {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(async () => {
      this.tenants = await TenantService.searchTenants(100, 0, '', false, undefined, searchString)
    }, 500)
  }

  private async mounted() {
    this.loading = true;
    try {
      await this.fetchTenants();
      await this.fetchApplications();
    } finally {
      this.loading = false;
    }
  }

  private async fetchApplications() {
    this.applications = await ApplicationService.getAllApplications();
  }

  private async fetchTenants() {
    this.tenants = await TenantService.getTenants();
  }

  private async addTenant() {
    this.loading = true;
    try {
      await TenantService.addTenant(this.tenant);
    } finally {
      await this.fetchTenants();
      this.loading = false;
    }
  }

  private async removeTenant() {
    // Refactor for bulk remove
    this.loading = true;
    try {
      for (const tenant of this.selectedTenants) {
        await TenantService.removeTenant(tenant);
      }
    } finally {
      this.loading = false;
      this.selectedTenants = [];

      await this.clearTenantId();

      await this.fetchTenants();
    }
  }

  private async clearTenantId() {
    this.tenantStore.setTenantId(null);
    await this.tenantStore.getTenantTheme();

    if (this.$route.name !== 'tenants') {
      this.$router.replace({name: 'tenants'})
    } else {
      if (this.$vuetify.theme.themes.currentTheme.colors.primary !== this.tenantStore.tenantTheme?.primary || this.$vuetify.theme.themes.currentTheme.colors.secondary !== this.tenantStore.tenantTheme?.secondary) {
        this.$vuetify.theme.themes.currentTheme = {
          ...this.$vuetify.theme.themes.currentTheme,
          colors: {
            ...this.$vuetify.theme.themes.currentTheme.colors,
            primary: this.tenantStore.tenantTheme?.primary || this.$vuetify.theme.themes.currentTheme.colors.primary,
            secondary: this.tenantStore.tenantTheme?.secondary || this.$vuetify.theme.themes.currentTheme.colors.secondary
          }
        }
      }
    }
  }


  private async toggleSuspendTenant() {
    // Refactor for bulk suspend
    for (const tenant of this.selectedTenants) {
      if (tenant.state === 'suspended') {
        tenant.state = 'active';
      } else {
        tenant.state = 'suspended';
      }
      await TenantService.updateTenant(tenant);
    }
    this.selectedTenants = [];
    await this.clearTenantId();
    await this.fetchTenants();
  }

  private selectApp(app: Application) {
    this.newApps.push(app);
  }

  private addApplicationRow(tenant: Tenant | null) {
    if (tenant && tenant.id) {
      this.newApps.push({ name: '', description: '' });
    }
  }

  private addApplicationAccess() {
    if (this.newApp && this.currentTenant) {
      this.applicationsToRegister = this.applicationsToRegister || []
      this.applicationsToRegister.push(this.newApp);
      this.newApp = null;
      this.newApps = [];
    }
  }
  private unregisterApplication(app: Application) {
    if (this.currentTenant) {
      this.currentTenant.applications = this.currentTenant.applications?.filter((a) => a.id !== app.id);
    }
  }
  private removeNewApplication(app: Application) {
    this.applicationsToRegister = this.applicationsToRegister.filter((a) => a.id !== app.id);
  }

  private async saveTenant() {
    this.loading = true;
    if (this.currentTenant) {
      if (this.currentTenant.applications && this.applicationsToRegister.length) {
        this.currentTenant.applications.push(...this.applicationsToRegister);
        this.applicationsToRegister = [];
      }

      const toBase64 = (file: File): Promise<any> => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      });

      this.currentTenant.theme_configuration = this.currentTenant.theme_configuration || { primary: '', secondary: '', logo: '' };
      this.currentTenant.theme_configuration.primary = this.primary;
      this.currentTenant.theme_configuration.secondary = this.secondary;
      if (this.logo[0]) {
        this.currentTenant.theme_configuration.logo = await toBase64(this.logo[0]);
        this.savedLogo = this.currentTenant.theme_configuration.logo;
      } else if (!this.logo[0] && !this.savedLogo) {
        this.currentTenant.theme_configuration.logo = '';
      }
      try {
        await TenantService.updateTenant(this.currentTenant);
      } catch (e) {
        const err = e as Error;
        console.error(err)
      } finally {
        this.loading = false;
        this.editDialog = false;
        await this.fetchTenants();
      }
    }
  }

  private manageTenant(tenantId: number) {
    this.tenantStore.setTenantId(tenantId);
    this.$router.push({ name: 'applications' })
  }

  private editTenant(item: Tenant) {
    this.currentTenant = {...item};
    this.primary = this.currentTenant.theme_configuration?.primary || this.$vuetify.theme.global.current.colors.primary;
    this.secondary = this.currentTenant.theme_configuration?.secondary || this.$vuetify.theme.global.current.colors.secondary;
    this.savedLogo = this.currentTenant.theme_configuration?.logo || '';

    this.editDialog = true;
  }

  private applicationOverlapsWithHybrid(item: any) {
    // Get the hybrid apps in the list, whether existing or newly added
    const currentHybridApps = this.currentTenant?.applications?.filter((app) => app.type === 'hybrid');
    const addedHybridApps = this.applications.filter((app) => this.applicationsToRegister.some((a) => a.id == app.id && app.type === 'hybrid'))

    if (item.raw.details?.applications) {
      // Get the application IDs of the apps within the hybrid app
      const hybridApplicationIds = currentHybridApps?.flatMap((h) => h.details.applications.map((a: { applicationId: number }) => a.applicationId));
      const addedHybridApplicationIds = addedHybridApps?.flatMap((h) => h.details.applications.map((a: { applicationId: number }) => a.applicationId));

      // Check if there's overlap between hybrid apps
      const currentHybridAppsSeen = item.raw.details.applications.some((app: { applicationId: number }) => hybridApplicationIds?.includes(app.applicationId));
      const addedHybridAppsSeen = item.raw.details.applications.some((app: { applicationId: number }) => addedHybridApplicationIds?.includes(app.applicationId));

      if (currentHybridAppsSeen || addedHybridAppsSeen) {
        item.errorMessage = 'This hybrid application contains an application that is part of another hybrid application, that has already been assigned to this tenant.';
        return true;
      }

      // Get the current and added app IDs, regardless of type
      const currentAppIds = this.currentTenant?.applications?.map((app) => app.id);
      const addedAppIds = this.applications.filter((app) => this.applicationsToRegister.some((a) => a.id == app.id)).map((app) => app.id);

      // Check if there's overlap between hybrid and standalone app
      const hybridAppContainsCurrentStandaloneApp = item.raw.details.applications.map((a: { applicationId: number }) => Number(a.applicationId)).some((id: number) => currentAppIds?.includes(id));
      const hybridAppContainsAddedStandaloneApp = item.raw.details.applications.map((a: { applicationId: number }) => Number(a.applicationId)).some((id: number) => addedAppIds?.includes(id));

      if (hybridAppContainsCurrentStandaloneApp || hybridAppContainsAddedStandaloneApp) {
        item.errorMessage = 'This hybrid application contains an application, that has already been assigned to this tenant.';
        return true;
      }
    } else {
      // Check if there's overlap between standalone and hybrid app
      const currentStandaloneExistsInHybrid = currentHybridApps?.some((h) => h.details.applications.map((a: { applicationId: number }) => a.applicationId).some((id: number) => item.raw.id === id))
      const addedStandaloneExistsInHybrid = addedHybridApps?.some((h) => h.details.applications.map((a: { applicationId: number }) => a.applicationId).some((id: number) => item.raw.id === id))

      if (currentStandaloneExistsInHybrid || addedStandaloneExistsInHybrid) {
        item.errorMessage = 'This application is part of a hybrid application, that has already been assigned to this tenant.'
        return true;
      }
    }
  }

  private get filteredApplications() {
    return this.applications.filter((a) => !this.currentTenant?.applications?.map((cta) => cta.id).includes(a.id) && !this.applicationsToRegister.map((atr) => atr.id).includes(a.id));
  }
}
</script>

<template>
  <div>
    <v-row class="pt-2 mx-4" justify="space-between" align="center" dense="">
      <v-col cols="12" md="6">
        <v-row align="center" dense="">
          <v-col cols="12">
            <h2>Tenant accounts</h2>
          </v-col>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="4" md="auto">
                <v-dialog width="500" @update:modelValue="tenant.name = ''">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" width="160" variant="tonal" color="primary">
                      <v-icon icon="mdi-store-plus" start></v-icon>
                      {{ mdAndUp ? 'Add tenant' : 'Add' }}
                    </v-btn>
                  </template>

                  <template v-slot:default="{ isActive }">
                    <v-card title="Add a tenant">
                      <v-card-text>
                        <v-form>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field hide-details density="compact" label="Name" v-model="tenant.name"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            :loading="loading"
                            :disabled="!tenant.name || loading"
                            color="primary"
                            @click="addTenant(); isActive.value = false"
                        >
                          Add tenant
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
              <v-col cols="4" md="auto">
                <v-btn size="small" width="160" variant="tonal" :disabled="!selectedTenants.length" color="primary" @click="toggleSuspendTenant">
                  <v-icon icon="mdi-store-off" start></v-icon>
                  {{ (mdAndUp ? (selectedTenants.length && selectedTenants[0].state === 'active' ? 'Suspend tenant' : 'Activate tenant') : (selectedTenants.length && selectedTenants[0].state === 'active' ? 'Suspend' : 'Activate')) }}
                </v-btn>
              </v-col>
              <v-col cols="4" md="auto">
                <v-dialog width="500">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" size="small" width="160" variant="tonal" :disabled="!selectedTenants.length" color="primary">
                      <v-icon icon="mdi-store-remove" start></v-icon>
                      {{ mdAndUp ? 'Remove tenant' : 'Remove' }}
                    </v-btn>
                  </template>
                  <template v-slot:default="{ isActive }">
                    <v-card max-width="460">
                      <v-card-title>Remove tenant</v-card-title>
                      <v-card-text>
                        Are you sure you want to remove this tenant?
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="isActive.value = false">
                          Cancel
                        </v-btn>
                        <v-btn
                            :loading="loading"
                            :disabled="loading"
                            color="primary"
                            @click="removeTenant(); isActive.value = false">
                          Remove
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <v-row>
          <v-col cols="12">
            <v-text-field hide-details density="compact" placeholder="Search tenants" append-inner-icon="mdi-magnify" v-model="searchNames"></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-data-table
        :loading="loading"
        color="primary"
        :items="tenants"
        :headers="headers"
        show-select
        v-model="selectedTenants"
        return-object
        height="calc(100vh - 180px)"
        fixed-header
        items-per-page="25"
    >
      <template v-slot:[`item.applications`]="{ item }">
        <div class="d-flex">
          <v-tooltip v-for="app of item.applications" :key="app.id" location="bottom" open-delay="200">
            <template v-slot:activator="{ props }">
              <v-avatar v-bind="props" size="32" color="secondary" rounded class="mx-1">{{ app.name.charAt(0) }}</v-avatar>
            </template>
            <template v-slot:default>
              <div v-if="app.type !=='hybrid'">{{ app.name }}</div>
              <div v-else>
                <v-row no-gutters justify="center">
                  <v-col cols="auto">
                    {{ app.name }}
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>Applications in this hybrid app:</v-col>
                </v-row>
                <v-row no-gutters v-for="subApp of app.details.applications" :key="subApp.id">
                  <v-col>
                    <v-icon>mdi-circle-small</v-icon>
                    {{ subApp.name }}
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <div v-if="item.state === 'active'" class="d-flex">
          <v-icon start icon="mdi-circle" color="success"></v-icon>
          Active
        </div>
        <div v-if="item.state === 'suspended'" class="d-flex">
          <v-icon start icon="mdi-circle" color="amber"></v-icon>
          Suspended
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ma-1" width="80" size="small" variant="tonal" color="primary" @click="editTenant(item);" v-if="item.state !== 'suspended'">Edit</v-btn>
        <v-btn class="ma-1" size="small" width="80" variant="tonal" @click="manageTenant(item.id)" color="primary" v-if="item.state !== 'suspended'">Manage</v-btn>
      </template>
    </v-data-table>
    <v-dialog width="500" v-model="editDialog" @update:modelValue="logo = []; applicationsToRegister = [];">
      <template v-slot:default>
        <v-card title="Edit tenant">
          <v-card-text>
            <v-form>
              <v-row align="center">
                <v-col cols="12" class="pb-0">
                  <p>
                    Tenant details
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Name" hide-details density="comfortable" v-model="currentTenant.name"></v-text-field>
                </v-col>
                <v-col cols="12" v-if="!currentTenant?.theme_configuration?.logo || !savedLogo" class="pb-0">
                  <v-file-input
                      color="primary" clearable :label="!logo.length ? 'Select or drop a logo' : ''" density="comfortable" prepend-icon="" v-model="logo"
                      counter
                      counter-size-string="Max 1MB"
                      show-size
                      :rules="rules"
                      accept="image/png">
                    <template v-slot:append-inner>
                      <v-icon color="primary" icon="mdi-image" v-if="!logo.length"></v-icon>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12" v-if="currentTenant?.theme_configuration?.logo">
                  <v-row dense v-if="savedLogo">
                    <v-col cols="12" class="d-flex justify-center">
                      <v-img :src="savedLogo" alt="logo" width="200" height="64"></v-img>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <v-btn size="small" color="primary" variant="plain" @click="savedLogo = ''; logo = [];">Clear logo</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Primary colour" hide-details density="comfortable" v-model="primary" color="primary">
                    <template v-slot:append-inner>
                      <v-menu :close-on-content-click="false" v-model="primaryDialog">
                        <template v-slot:activator="{ props }">
                          <v-icon :color="primary" v-bind="props" icon="mdi-palette"></v-icon>
                        </template>
                        <v-color-picker mode="hex" v-model="primary"></v-color-picker>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field label="Secondary colour" hide-details density="comfortable" v-model="secondary" color="secondary">
                    <template v-slot:append-inner>
                      <v-menu :close-on-content-click="false" v-model="secondaryDialog">
                        <template v-slot:activator="{ props }">
                          <v-icon :color="secondary" v-bind="props" icon="mdi-palette"></v-icon>
                        </template>
                        <v-color-picker mode="hex" v-model="secondary" ></v-color-picker>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-row no-gutters justify="space-between">
                    <v-col cols="auto">
                      <p>
                        Applications
                      </p>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn size="small" color="primary" variant="plain" @click="addApplicationRow(currentTenant)"><v-icon start icon="mdi-plus-circle"></v-icon> Add </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-list>
                    <v-list-item v-for="app of currentTenant.applications" :key="app.name" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
                      <v-row no-gutters class="flex-nowrap" justify="space-between" align="center">
                        <v-col cols="11">
                          <v-list-item-title>{{ app.name }}</v-list-item-title>
                          <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                        </v-col>
                        <v-col cols="1">
                          <v-list-item-action>
                            <v-dialog :attach="true">
                              <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" icon variant="plain" color="primary" size="small"><v-icon icon="mdi-delete" size="large"></v-icon></v-btn>
                              </template>
                              <template v-slot:default="{ isActive }">
                                <v-card max-width="460">
                                  <v-card-title>Remove application from tenant account</v-card-title>
                                  <v-card-text>
                                    Are you sure you want to remove this application from this tenant account? Users in this tenant will no longer have access to the application or its data.
                                  </v-card-text>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="isActive.value = false">
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                        :loading="loading"
                                        :disabled="loading"
                                        color="primary"
                                        @click="unregisterApplication(app); isActive.value = false">
                                      Remove
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </template>
                            </v-dialog>
                          </v-list-item-action>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item v-for="app of applicationsToRegister" :key="app.name" style="background-color: rgba(0,0,0,0.05)" rounded class="my-2">
                      <v-row no-gutters justify="space-between" align="center">
                        <v-col cols="11">
                          <v-list-item-title>{{ app.name }}</v-list-item-title>
                          <v-list-item-subtitle>{{ app.description }}</v-list-item-subtitle>
                        </v-col>
                        <v-col cols="1">
                          <v-list-item-action>
                            <v-btn icon variant="plain" color="primary" size="small" @click="removeNewApplication(app)"><v-icon icon="mdi-delete" size="large"></v-icon></v-btn>
                          </v-list-item-action>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-list-item
                        class="mb-1 px-0 pt-0"
                        v-for="app of newApps"
                        :key="app.name"
                    >
                      <v-combobox
                          class="app-combobox"
                          clearable
                          :items="filteredApplications"
                          item-title="name"
                          hide-details
                          density="comfortable"
                          placeholder="Search applications"
                          return-object
                          :menu-props="{ maxWidth: 452 }"
                      >
                        <template v-slot:selection="{ item }">
                          <div class="d-flex flex-column">
                            <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                          </div>
                        </template>
                        <template v-slot:item="{ item }">
                          <div v-if="applicationOverlapsWithHybrid(item)" class="d-flex flex-column">
                            <v-tooltip :text="item.errorMessage" location="bottom" open-delay="200">
                              <template v-slot:activator="{ props }">
                                <div v-bind="props">
                                  <v-list-item @click="newApp = item.raw; addApplicationAccess()" :disabled="true">
                                    <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                                  </v-list-item>
                                </div>
                              </template>
                            </v-tooltip>
                          </div>
                          <div v-else class="d-flex flex-column">
                            <v-list-item @click="newApp = item.raw; addApplicationAccess()">
                              <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                              <v-list-item-subtitle>{{ item.raw.description }}</v-list-item-subtitle>
                            </v-list-item>
                          </div>
                        </template>
                      </v-combobox>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                @click="saveTenant"
                :loading="loading"
                :disabled="loading || invalidLogo"
            >
              Save tenant
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<style>
</style>

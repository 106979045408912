<script lang="ts">
import { Component, Vue, toNative, Prop } from "vue-facing-decorator";

@Component({})
class LoginError extends Vue {
  @Prop({default: ''}) private error!: string;

}
export default toNative(LoginError)
</script>

<template>
  <v-alert
      density="compact"
      class="text-left"
      v-if="error"
      type="error"
      color="error"
  >
    <span class="text-white">{{ error }}</span>
  </v-alert>
</template>

<style scoped>

</style>
<script lang="ts">
import {Vue, Component, Prop, Emit} from 'vue-facing-decorator';

@Component({})
export default class Toast extends Vue {
  @Prop({default: ''}) private heading!: string;
  @Prop({default: ''}) private body!: string;
  @Prop({default: 'error'}) private type!:  any;
  @Prop({default: true}) private showToastBoolean!: boolean;
  private mutableShowToastBoolean = true;

  private mounted() {
    setTimeout(() => {
      this.updateShowToast(false);
      this.mutableShowToastBoolean = false;

    }, 10000);
  }

  @Emit()
  private updateShowToast(show: boolean) {
    return show;
  }

  private get icon() {
    const icons: Record<string, string> = {
      error: 'mdi-alert',
      warning: 'mdi-alert-circle',
      info: 'mdi-information-circle',
      success: 'mdi-check-circle'
    }

    return icons[this.type];
  }
}
</script>

<template>
  <transition name="toast-show">
    <div v-if="mutableShowToastBoolean">
      <v-alert
          border="start"
          :color="type"
          :closable="true"
          density="compact"
          @click:close="updateShowToast(false)"
      >
        <v-row no-gutters class="fill-height" align="center">
          <v-col cols="12" md="auto">
            <span class="mr-3 font-weight-bold">{{heading}}</span>
          </v-col>
          <v-col cols="12" md="auto">
            <span>{{body}}</span>
          </v-col>
        </v-row>
        <template v-slot:prepend>
          <v-icon :icon="icon" color="white" class="mt-1"></v-icon>
        </template>
      </v-alert>
    </div>
  </transition>

</template>

<style scoped>

</style>
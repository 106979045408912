<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import { Application, User } from '@/types';
import { useDisplay } from 'vuetify';
import TenantService from '@/services/tenant.service';
import { useTenantStore } from '@/stores/tenantStore';
import UserService from '@/services/user.service';
import { useNotificationStore } from '@/stores/notificationStore';
import ApplicationService from '@/services/application.service';
import StandaloneApplicationConfiguration from '@/views/StandaloneApplicationConfiguration.vue';
import HybridApplicationConfiguration from '@/views/HybridApplicationConfiguration.vue';

@Component({
  components: { HybridApplicationConfiguration, StandaloneApplicationConfiguration }
})
export default class ApplicationConfiguration extends Vue {
  private tenantStore = useTenantStore();
  private currentApplication: Application | null = null;
  private applicationId = '';
  private applicationRolesLookupData: { [role: string]: { items: { id: string | number, name: string }[], label: string, desc?: string } } = {};

  private async mounted() {
    if (this.$route.params.applicationId) {
      this.applicationId = Array.isArray(this.$route.params.applicationId) ? this.$route.params.applicationId[0] : this.$route.params.applicationId;
    }
    await this.fetchApplication()
  }

  private async fetchApplication() {
    if (this.tenantStore.tenantId) {
      this.currentApplication = await TenantService.getApplication(this.tenantStore.tenantId, Number(this.applicationId));
    }
  }
}
</script>

<template>
  <div>
    <v-row class="pt-2 mx-4" justify="space-between" align="center" :dense="true">
      <v-col cols="12" md="6">
        <v-row align="center" :dense="true">
          <v-col cols="12">
            <v-row no-gutters align="center">
              <v-col cols="auto">
                <v-btn @click="$router.push({ name: 'applications' })" class="mr-4" size="28" icon color="primary" variant="tonal"><v-icon>mdi-arrow-left</v-icon></v-btn>
              </v-col>
              <v-col>
                <h2>Application configuration
                  <v-chip variant="tonal" color="primary" label class="ml-md-4"> <span class="font-weight-light">{{ currentApplication ? currentApplication.name : '' }}</span></v-chip>
                </h2>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="currentApplication">
      <StandaloneApplicationConfiguration
        v-if="currentApplication.type !== 'hybrid'"
        :currentApp="currentApplication"
        :applicationRolesLookupData="applicationRolesLookupData"
      />
      <HybridApplicationConfiguration
        v-else
        :currentApp="currentApplication"
        :applicationRolesLookupData="applicationRolesLookupData"
      />
    </div>
  </div>
</template>

<style>
</style>

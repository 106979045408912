import Axios from 'axios'
import { getAPIUrl } from './util';
import {useUserStore} from "@/stores/userStore";
import {useNotificationStore} from "@/stores/notificationStore";

export const appAxios = Axios.create();

appAxios.interceptors.response.use((resp: any) => resp, handleError);
appAxios.interceptors.request.use((req: any) => req, handleError);

appAxios.defaults.baseURL = getAPIUrl();

async function handleError(error: any) {
  const notificationStore = useNotificationStore();
  const userStore = useUserStore();
  if (!Axios.isCancel(error)) {
    notificationStore.addToast({ message: error })
    console.error(error)
  }
  if (Axios.isCancel(error)) {
    return Promise.reject(error);
  }

  if (error.response) {
    if (error.response.status === 401) {
      await userStore.userLogout()
      return Promise.reject(error);
    } else if (error.response.data && (error.response.data.error || error.response.data.errors)) {
      error.message = error.response.data.error || error.response.data.errors[0];
    }
  }

  return Promise.reject(error);
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_ctx.error)
    ? (_openBlock(), _createBlock(_component_v_alert, {
        key: 0,
        density: "compact",
        class: "text-left",
        type: "error",
        color: "error"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.error), 1)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
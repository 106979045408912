import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    timeout: "1500",
    modelValue: _ctx.snackbarActive,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.snackbarActive) = $event)),
      _ctx.updateShowSnackbar
    ]
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_icon, { start: "" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.icon), 1)
        ]),
        _: 1
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.body), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "onUpdate:modelValue"]))
}
<script lang="ts">
import {Vue, Component, Watch} from 'vue-facing-decorator';
import {useDisplay} from "vuetify";
import { useUserStore } from "@/stores/userStore";
import {useTenantStore} from "@/stores/tenantStore";
import {useNotificationStore} from "@/stores/notificationStore";
import Toast from '@/components/Toast.vue';
import Snackbar from '@/components/Snackbar.vue';

@Component({ components: { Snackbar, Toast } })
export default class Layout extends Vue {
  private userStore = useUserStore();
  private tenantStore = useTenantStore();
  private notificationStore = useNotificationStore();

  private mdAndUp = useDisplay().mdAndUp;
  private mdAndDown = useDisplay().mdAndDown;
  private lgAndUp = useDisplay().lgAndUp;
  private smAndDown = useDisplay().smAndDown;
  private smAndUp = useDisplay().smAndUp;

  private activeBottomMenu = 0;
  private activeSubMenu = 0;
  private drawer = this.mdAndUp;

  private get landscapeLogoSrc() {
    const tenantLogo = this.tenantStore.tenantTheme?.logo;
    if (!tenantLogo || !tenantLogo.length) {
      return process.env.VUE_APP_PUBLIC_CONTENT_URL + '/themes/default.png';
    } else {
      return tenantLogo;
    }
  }

  private async clearTenantId() {
    this.tenantStore.setTenantId(null);
    await this.tenantStore.getTenantTheme();

    if (this.$route.name !== 'tenants') {
      this.$router.replace({name: 'tenants'})
    } else {
      if (this.$vuetify.theme.themes.currentTheme.colors.primary !== this.tenantStore.tenantTheme?.primary || this.$vuetify.theme.themes.currentTheme.colors.secondary !== this.tenantStore.tenantTheme?.secondary) {
        this.$vuetify.theme.themes.currentTheme = {
          ...this.$vuetify.theme.themes.currentTheme,
          colors: {
            ...this.$vuetify.theme.themes.currentTheme.colors,
            primary: this.tenantStore.tenantTheme?.primary || this.$vuetify.theme.themes.currentTheme.colors.primary,
            secondary: this.tenantStore.tenantTheme?.secondary || this.$vuetify.theme.themes.currentTheme.colors.secondary
          }
        }
      }
    }
  }

  private checkRemoveToast(show: boolean, key: string): void {
    if (!show) {
      this.notificationStore.removeToast(key);
    }
  }

  private checkRemoveSnackbar(show: boolean, key: string): void {
    if (!show) {
      this.notificationStore.removeSnackbar(key); 
    }
  }
}
</script>

<template>

  <div class="fill-height" :key="$vuetify.theme.themes.currentTheme">
    <div id="nav" v-if="$route.matched.some((r) => r.path !== '404')">
      <v-navigation-drawer
          color="tertiary"
          class="elevation-0"
          v-model="drawer"
      >
        <v-row class="flex-column justify-space-between fill-height">
          <v-col>
            <v-card flat color="transparent">
              <v-card-title v-if="mdAndUp" class="d-flex justify-center">
                <v-img :src="landscapeLogoSrc" cover max-width="200" max-height="100" @click="!!tenantStore.tenantId ? $router.push({name: 'applications'}) : $router.push({name: 'tenants'})"/>
              </v-card-title>
              <v-list
                  :disabled="!tenantStore.tenantId"
                  nav
                  base-color="white"
                  color="primary"
                  class="custom-list"
                  :class="{'mt-4': !!smAndDown}"
              >
                <v-list-item link to="/" :disabled="!tenantStore.tenantId">
                  <template v-slot:prepend>
                    <v-icon color="primary" end>mdi-view-dashboard</v-icon>
                  </template>
                  <v-list-item-title>Applications</v-list-item-title>
                </v-list-item>
                <v-list-item exact link to="/users" :disabled="!tenantStore.tenantId" v-if="userStore.hasPermission('user:read')">
                  <template v-slot:prepend>
                    <v-icon color="primary" end>mdi-account-group</v-icon>
                  </template>
                  <v-list-item-title>User management</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-divider class="mx-4" v-if="userStore.hasPermission('administration:manage')"></v-divider>
              <v-list
                  density="compact"
                  nav
                  base-color="white"
                  color="primary"
                  class="custom-list"
                  v-model="activeSubMenu"
                  v-if="userStore.hasPermission('administration:manage')"
              >
                <v-list-item @click="clearTenantId" :disabled="!tenantStore.tenantId" :ripple="false">
                  <template v-slot:prepend>
                    <v-icon color="primary" end :class="{'darken-menu': !tenantStore.tenantId}">mdi-arrow-left</v-icon>
                  </template>
                  <v-list-item-title :class="{'darken-menu': !tenantStore.tenantId}">Exit tenant</v-list-item-title>
                </v-list-item>
                <v-list-item exact link to="/admin/tenants">
                  <template v-slot:prepend>
                    <v-icon color="primary" end>mdi-store</v-icon>
                  </template>
                  <v-list-item-title>Tenant accounts</v-list-item-title>
                </v-list-item>
                <v-list-item exact link to="/admin/superusers">
                  <template v-slot:prepend>
                    <v-icon color="primary" end>mdi-account-supervisor</v-icon>
                  </template>
                  <v-list-item-title>Super user management</v-list-item-title>
                </v-list-item>
                <v-list-item exact link to="/admin/applications">
                  <template v-slot:prepend>
                    <v-icon color="primary" end>mdi-application-cog</v-icon>
                  </template>
                  <v-list-item-title>Application management</v-list-item-title>
                </v-list-item>
                <v-list-item link to="/admin/nodered">
                  <template v-slot:prepend>
                    <v-icon color="primary" end>mdi-vector-line</v-icon>
                  </template>
                  <v-list-item-title>Node-RED management</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="auto">
            <v-card flat color="transparent">
              <v-list
                nav
                base-color="primary"
                density="compact"
                class="custom-list"
                v-model="activeBottomMenu"
              >
                <v-list-item color="primary">
                  <v-list-item @click="userStore.userLogout()" :ripple="false">
                    <template v-slot:prepend>
                      <v-icon end>mdi-logout-variant</v-icon>
                    </template>
                      <v-list-item-title>Log out</v-list-item-title>
                  </v-list-item>
                  <v-list-item :to="{name: 'knowledgebase'}">
                    <template v-slot:prepend>
                      <v-icon end>mdi-help-circle</v-icon>
                    </template>
                    <v-list-item-title>Help</v-list-item-title>
                  </v-list-item>
                  <v-list-item :ripple="false" :to="{name: 'privacy'}">
                    <template v-slot:prepend>
                      <v-icon end>mdi-shield-lock</v-icon>
                    </template>
                    <v-list-item-title>Privacy Policy</v-list-item-title>
                  </v-list-item>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="auto" class="pa-0 d-flex justify-center" v-if="false">
            <v-switch class="mt-0" @update:modelValue="setDebugMode({mode: 'new_hierarchy', value: $event})"></v-switch>
          </v-col>
        </v-row>
      </v-navigation-drawer>
      <v-app-bar height="64" flat density="compact" v-if="mdAndDown">
        <v-row justify="space-between" align="center">

          <v-col cols="auto" class="mx-2">
            <v-icon color="primary" @click.stop="drawer = !drawer"  :size="smAndDown ? 32 : 52">mdi-menu</v-icon>
          </v-col>
          <v-col class="d-flex align-center justify-center">
            <router-link to="/"><v-img :src="landscapeLogoSrc" cover max-width="180" height="64" /></router-link>
          </v-col>

          <v-col cols="auto" v-if="smAndUp" >
            <v-sheet :width="smAndDown ? 32 : 52" height="52" color="transparent"></v-sheet>
          </v-col>
        </v-row>
      </v-app-bar>
    </div>
    <v-main class="fill-height">
      <div class="snackbar" style="z-index: 9999;">
        <Snackbar class="my-1 mx-1" v-for="(snackbar, key) in notificationStore.snackbarMessages" :key="key" :ref="key" :body="snackbar.message" :open="!!notificationStore.snackbarMessages" :icon="snackbar.icon" @updateShowSnackbar="checkRemoveSnackbar($event, key)" />
      </div>
      <div class="toast" style="z-index: 9999;">
        <Toast class="my-1 mx-1" v-for="(message, key) in notificationStore.toastMessages" :key="key" :ref="key" type="error" heading="An error occurred:" :body="message" @updateShowToast="checkRemoveToast($event, key)" />
      </div>
      <router-view />
    </v-main>
  </div>
</template>

<style scoped>
.toast {
  margin: 4px;
  position: absolute;
  bottom: 0;
}
.darken-menu {
  opacity: 0.5 !important;
}
</style>
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-white text-h5 font-weight-bold"
}
const _hoisted_2 = {
  key: 1,
  class: "text-white text-h5 font-weight-bold"
}
const _hoisted_3 = {
  key: 2,
  class: "text-white text-h5 font-weight-bold"
}
const _hoisted_4 = {
  key: 0,
  class: "text-white text-body-1"
}
const _hoisted_5 = {
  key: 1,
  class: "text-white text-body-1"
}
const _hoisted_6 = {
  key: 2,
  class: "text-white text-body-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_LoginError = _resolveComponent("LoginError")!
  const _component_ActionButton = _resolveComponent("ActionButton")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_row, {
    justify: "center",
    align: "center"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_col, {
        cols: "12",
        md: "10",
        lg: "6",
        class: "pa-4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            color: "background",
            class: "pl-4 pr-6 py-6"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, {
                "no-gutters": "",
                align: "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.resent=false; _ctx.error=''; _ctx.$emit('go-to:email');}),
                        icon: "",
                        color: "secondary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => [
                              _createTextVNode("mdi-arrow-left")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { class: "text-center" }, {
                    default: _withCtx(() => [
                      (!_ctx.resent && !_ctx.activating)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Reset password"))
                        : (_ctx.resent && !_ctx.activating)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Password reset"))
                          : _createCommentVNode("", true),
                      (_ctx.activating)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Resend registration email"))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_sheet, {
                        width: "36",
                        height: "36",
                        color: "transparent"
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                justify: "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_LoginError, { error: _ctx.error }, null, 8, ["error"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "text-center"
                  }, {
                    default: _withCtx(() => [
                      (!_ctx.resent && !_ctx.activating)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "If you've lost or want to reset your password, please confirm."))
                        : _createCommentVNode("", true),
                      (!_ctx.resent && _ctx.activating)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Click the button below to request a new registration code."))
                        : _createCommentVNode("", true),
                      (_ctx.resent)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, "An email has been sent. " + _toDisplayString(!_ctx.activating ? 'Please follow the instructions to reset your password.' : ''), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, { justify: "center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "relative d-flex justify-center" }, {
                    default: _withCtx(() => [
                      (!_ctx.resent && !_ctx.activating)
                        ? (_openBlock(), _createBlock(_component_ActionButton, {
                            key: 0,
                            onClick: _ctx.resetPassword,
                            text: "Reset password",
                            color: "secondary",
                            loading: _ctx.loading
                          }, null, 8, ["onClick", "loading"]))
                        : _createCommentVNode("", true),
                      (!_ctx.resent && _ctx.activating)
                        ? (_openBlock(), _createBlock(_component_ActionButton, {
                            key: 1,
                            onClick: _ctx.resendActivationCode,
                            text: "Resend registration email",
                            color: "secondary",
                            loading: _ctx.loading
                          }, null, 8, ["onClick", "loading"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
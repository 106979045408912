import {defineStore} from "pinia";
import TenantService from "@/services/tenant.service";

interface State {
  tenantId: number | null;
  tenantTheme: any;
  defaultTheme: any;
}

export const useTenantStore = defineStore( 'tenantStore', {
  state: (): State => ({
    tenantId: Number(localStorage.getItem('application_framework_tenant')) || null,
    tenantTheme: JSON.parse(localStorage.getItem('application_framework_tenant_theme') as string),
    defaultTheme: {}
  }),
  actions: {
    setDefaultTheme(theme: { primary: string; secondary: string; logo: string }) {
      this.defaultTheme = theme;
    },
    setTenantId(tenantId: number | null) {
      localStorage.setItem('application_framework_tenant', tenantId ? tenantId.toString() : '')
      this.tenantId = tenantId
    },
    async getTenantTheme(tenantId?: number) {
      if (tenantId) {
        const tenant = await TenantService.getTenant(tenantId);

        this.setTenantTheme(tenant.theme_configuration);
        return this.tenantTheme;
      }

      this.setTenantTheme(this.defaultTheme)
      return this.defaultTheme;
    },
    setTenantTheme(theme: { primary: string; secondary: string; logo: string }) {
      const stringTheme = JSON.stringify(theme);
      localStorage.setItem('application_framework_tenant_theme', stringTheme);

      this.tenantTheme = theme;
    }
  },
})
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    small: _ctx.small,
    block: _ctx.block,
    height: _ctx.height,
    width: _ctx.width,
    fixed: _ctx.fixed,
    bottom: _ctx.bottom,
    color: _ctx.color,
    class: _normalizeClass(["text-none action-button text-white", _ctx.css]),
    loading: _ctx.loading,
    onClick: _ctx.click,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.icon.length)
          ? (_openBlock(), _createBlock(_component_v_icon, {
              key: 0,
              start: "",
              dark: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.icon), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.disabled ? 'grey--text' : '')
        }, _toDisplayString(_ctx.text), 3),
        (_ctx.iconRight.length)
          ? (_openBlock(), _createBlock(_component_v_icon, {
              key: 1,
              end: "",
              dark: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.iconRight), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["small", "block", "height", "width", "fixed", "bottom", "color", "class", "loading", "onClick", "disabled"]))
}
<script lang="ts">
import {Vue, Component} from 'vue-facing-decorator';
import {Application} from "@/types";
import {useTenantStore} from "@/stores/tenantStore";
import ApplicationService from "@/services/application.service";
import {useDisplay} from "vuetify";
import {useUserStore} from "@/stores/userStore";

@Component({})
export default class Applications extends Vue {
  private tenantStore = useTenantStore();
  private userStore = useUserStore();
  private applications: Application[] = [];
  private loaded = false;
  private apps = [
    { id: 1, name: 'Sensor metrics', type: 'database' },
    { id: 2, name: 'Legionella monitoring', type: 'water' },
    { id: 3, name: 'Temperature monitoring', type: 'temperature' },
    { id: 4, name: 'Energy monitoring', type: 'energy' },
  ];
  private currentApp: Application | null = null;
  private headers: Array<{ title: string; value: string; sortable: boolean; width?: string }> = [
    { title: '', value: 'avatar', sortable: false, width: '60px' },
    { title: 'Name', value: 'full_name', sortable: true },
    { title: 'Email', value: 'email', sortable: true },
    { title: 'Role', value: 'role', sortable: true },
  ];

  private async mounted() {
    if (this.tenantStore.tenantId) {
      this.applications = this.userStore.user.applications;
      if (this.userStore.hasPermission('application:manage')) {
        this.applications = await ApplicationService.getApplicationsForTenant(this.tenantStore.tenantId)
      }
      this.loaded = true;
    }
  }

  private fullName(first: string, last: string): string {
    return `${first} ${last}`
  }

  private truncate(text: string) {
    return text.substring(0, 80) + "...";
  }
}
</script>

<template>
  <div>
    <v-row class="pt-2 mx-4" justify="space-between" align="center" dense="">
      <v-col cols="12" md="6">
        <v-row align="center" dense="">
          <v-col cols="12">
            <h2>Applications</h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-progress-linear indeterminate color="primary" v-if="!applications.length && !loaded"></v-progress-linear>
      </v-col>
    </v-row>
    <v-container>
      <v-row v-if="applications.length && loaded">
        <v-col
            v-for="app of applications"
            :key="app.id"
            cols="12"
            md="4"
        >
          <v-card height="230" elevation="0" color="primary" variant="tonal" class="d-flex flex-column fill-height justify-space-between">
            <v-row no-gutters>
              <v-col cols="3">
                <div class="pa-4">
                  <v-avatar size="50" color="secondary" rounded class="mx-1">{{ app.name.charAt(0) }}</v-avatar>
                </div>
              </v-col>
              <v-col cols="9">
                <v-card-title class="text-capitalize font-weight-bold text-body-1 pt-5 text-wrap name-height text-white">
                  {{ app.name }}
                </v-card-title>
                <v-card-subtitle class="text-capitalize text-white">
                  {{ app.type }}
                </v-card-subtitle>
                <v-card-text class="text-white">
                  <span v-if="app.description?.length > 30">{{ truncate(app.description) }}</span>
                  <span v-else>{{ app.description }}</span>
                </v-card-text>
              </v-col>
            </v-row>
            <v-card-actions class="px-4">
              <v-btn v-if="userStore.hasPermission('application:manage')" :to="`/applications/${app.id}/configuration`"><v-icon start icon="mdi-application-cog-outline"></v-icon>Configure</v-btn>
              <v-spacer></v-spacer>
              <v-btn :to="`/applications/${app.id}`" :disabled="!(userStore.user?.applications.map((a) => a.id).includes(Number(app.id)) || userStore.hasPermission('administration:read'))"><v-icon start icon="mdi-application-export"></v-icon>Access</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="!applications.length && loaded" justify="center">
        <v-col cols="auto">
          <h3 class="text-disabled font-weight-regular">You do not have access to any applications.</h3>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style>
  .name-height {
    line-height: 1.30rem !important;
  }
</style>

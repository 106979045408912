<script lang="ts">
import {Component, Vue, toNative, Prop, Emit} from "vue-facing-decorator";

@Component({})
class ActionButton extends Vue {
  @Prop() private action: any;
  @Prop() private text!: string;
  @Prop() private css!: string;
  @Prop() private disabled!: boolean;
  @Prop() private block!: boolean;
  @Prop() private small!: boolean;
  @Prop({default: undefined}) private width!: number | string;
  @Prop({default: undefined}) private height!: number | string;
  @Prop({default: false}) private fixed!: boolean;
  @Prop({default: false}) private loading!: boolean;
  @Prop({default: false}) private bottom!: boolean;
  @Prop({ default: '' }) private icon!: string;
  @Prop({ default: '' }) private iconRight!: string;
  @Prop({ default: 'primary' }) private color!: string;

  @Emit()
  private click() {
    return true;
  }
}
export default toNative(ActionButton)
</script>

<template>
  <v-btn :small="small" :block="block" :height="height" :width="width" :fixed="fixed" :bottom="bottom" :color="color" class="text-none action-button text-white" :class="css" :loading="loading" @click="click" :disabled="disabled">
    <div class="d-flex align-center">
      <v-icon start dark v-if="icon.length">{{ icon }}</v-icon>
      <span :class="disabled ? 'grey--text' : ''">{{ text }}</span>
      <v-icon end dark v-if="iconRight.length">{{ iconRight }}</v-icon>
    </div>
  </v-btn>
</template>

<style scoped>

</style>
<template>
  <v-app id="app">
    <Layout v-if="$route.path.startsWith('/') && !$route.path.endsWith('login') && !$route.path.endsWith('reset')" />
    <LoginFlow v-if="$route.path.endsWith('login')" />
    <ResetFlow v-if="$route.path.endsWith('reset')" />
  </v-app>
</template>
<script lang="ts">
import {Component, Vue, toNative, Watch} from "vue-facing-decorator";
import LoginFlow from "@/views/login/LoginFlow.vue";
import ResetFlow from "@/views/login/ResetFlow.vue";
import Layout from "@/views/Layout.vue";
import {useTenantStore} from "@/stores/tenantStore";
import {RouteScope} from "@/router";

@Component({ components: { LoginFlow, ResetFlow, Layout }})
class App extends Vue {
  private tenantStore = useTenantStore();
  private mounted() {
    document.title = 'Bell Application Framework'
  }

  @Watch('$route')
  private async onRouteChange() {
    if (this.$route.meta.scope === RouteScope.Public) {
      this.tenantStore.setTenantId(null);
      await this.tenantStore.getTenantTheme();
    }
    if (this.$vuetify.theme.themes.currentTheme.colors?.primary !== this.tenantStore.tenantTheme?.primary || this.$vuetify.theme.themes.currentTheme.colors?.secondary !== this.tenantStore.tenantTheme?.secondary) {
      this.$vuetify.theme.themes.currentTheme = {
        ...this.$vuetify.theme.themes.currentTheme,
        colors: {
          ...this.$vuetify.theme.themes.currentTheme.colors,
          primary: this.tenantStore.tenantTheme?.primary || this.$vuetify.theme.themes.currentTheme.colors?.primary || this.tenantStore.defaultTheme.primary,
          secondary: this.tenantStore.tenantTheme?.secondary || this.$vuetify.theme.themes.currentTheme.colors?.secondary || this.tenantStore.defaultTheme.secondary
        }
      }
    }
  }
}
export default toNative(App)
</script>
<style>
</style>

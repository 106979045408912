import {User} from "@/types";
import {appAxios} from "@/app.axios";
import Transformation from "@/classes/transformation";

export class AdminService {
  public async searchSuperUsers(
    take = 0, skip = 0, sort = '',
    desc = false, filters: Record<string, Record<string, boolean>> = {},
    searchNames: string | null = null): Promise<User[]> {
    let filtersString = '';

    const filterQuery = Transformation.filterMapToQueryString(filters);

    if (filterQuery) {
      filtersString += `&${filterQuery}`;
    }
    if (searchNames) {
      filtersString += `&searchNames=${encodeURIComponent(searchNames)}`;
    }
    const url = `/admin/superusers?take=${take}&skip=${skip}&sort=${sort}&desc=${desc}${filtersString}`;
    return (await appAxios.get(url)).data;

  }
  public async addSuperUser(user: { first_name: string; last_name: string; email: string }): Promise<User> {
    const url = `/admin/users/add`;
    return (await appAxios.post(url, user)).data;
  }
  public async updateSuperUser(userId: number, user: { first_name: string; last_name: string; }): Promise<User> {
    const url = `/admin/users/${userId}`;
    return (await appAxios.put(url, user)).data;
  }
  public async removeSuperUser(userId: number): Promise<{ success: boolean }> {
    const url = `/admin/users/${userId}`;
    return (await appAxios.delete(url)).data;
  }
}

export default new AdminService();